import { PaginationState } from "@tanstack/react-table";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import DocumentsService, {
  viewAndPrintDocument,
} from "../../../common/api/documentsApi";
import ModalButton from "../../../shared/Ui/Buttons/ModalButtons";
import { EmptyListPlaceholderContent } from "../../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../../shared/Ui/ModalComponent/ModalWindow";
import Stepper from "../../../shared/components/Stepper/Stepper";
import Pagination from "../../../shared/components/Table/Pagination";
import { ITable } from "../../../shared/components/Table/types";
import { DocumentUpload } from "../Upload/DocumentUpload/DocumentUpload";
import DocumentsSearch from "../Upload/DocumentsSearch/DocumentsSearch";
import MarkScan from "../Upload/MarkScan/MarkScan";
import { setBlocker, clearBlocker } from '../../../shared/slice/blockerSlice'
import style from "./DocumentInventoryWriteOff.module.css";
import { format } from "date-fns";
// @ts-ignore
import { createDocument } from "../../../common/api/notificationApi";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import AlertBox from "../../../shared/Ui/AlertBox/AlertBox";
import Loader from "../../../shared/Ui/Loader/Loader";
import StatusBadge from "../../../shared/Ui/StatusBadge/StatusBadge";
import { useAppDispatch, useAppSelector } from "../../../shared/hook";
import CheckResult from "../Upload/CheckResults/CheckResult";
import { deleteDataDocument } from "./../../../shared/slice/documentState";
import CheckBox from "../../../shared/Ui/CheckBox/CheckBox";
import { submitXmlProduct } from "../../../shared/signature/documentsSignature";
import PageNotRole from "../../../shared/Ui/PageNotFound/PageNotRole";
import { getDescription, getStatusType } from "../../../shared/documentUtils/dictionary";
import { ModalResult } from "../../../shared/components/ModalResult/ModalResult";

function DocumentInventoryWriteOffOrders() {
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);
  const navigate = useNavigate();
  const { writeOffDocumentId } = useParams();
  const dispatch = useAppDispatch();
  const documentState = useAppSelector((state) => state.documentState);
  const [tableData, setTableData] = useState<ITable>();
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [errorModal, setErrorModal] = useState<any>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(null);
  const [deleteMarks, setDeleteMarks] = useState<number[]>([]);
  const [isDocumentSearchModalOpen, setIsDocumentSearchModalOpen] =
    useState<boolean>(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] =
    useState<boolean>(false);
  const [isMarkScanModal, setIsMarkScanModal] = useState<boolean>(false);
  const [isCheckOpen, setIsCheckOpen] = useState<any>(null);
  const userData = useAppSelector((state) => state.auth.userData);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [toggleOnLoad, setToggleOnLoad] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [status, setStatus] = useState<any>("new");
  const roleMatrix = useAppSelector((state) => state.roleMatrix);
  const [role, setRole] = useState<any>(null);
  const [resModal, setResModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [statusType, setStatusType] = useState({
    type: "new",
    label: t("documentStatus.new"),
  });
  const [initialValues, setInitialValues] = useState<any>({
    note: null, // Примечание
    refWithdrawalReason: null, //Причина вывода из оборота
    foundationDocumentName: null,
    foundationDocumentNumber: null,
    foundationDocumentDate: null,

    id: null, // ИД
    docNumber: null, // Номер документа

    orgId: "",
    createUser: "",
    createdAt: "",
    // receiver: "",
    // sender: "",
    status: "new",
  });

  useEffect(() => {
    const roleObj = () => {
      let roleWithSpecificAction = roleMatrix?.roleMatrix?.find((item) => item.id === 12)
      let roleDraft = roleMatrix?.roleMatrix?.find((item) => item.id === 6);
      if (!roleWithSpecificAction.view) {
        roleWithSpecificAction = roleMatrix?.roleMatrix?.find((item) => item.id === 5)
      }
      return {
        roleDoc: roleWithSpecificAction?.specificAction?.documents?.[0] || false,
        roleDraft: roleDraft || false,
        roleAccepted: roleWithSpecificAction || false
      };

    }

    setRole(roleObj)

    onLoad();
    setIsLoading(false)
    if (documentState.isSafe) {
      dispatch(setBlocker(true))
      setIsFormChanged(true);
    }
  }, [
    pagination,
    isDocumentSearchModalOpen,
    isMarkScanModal,
    isCheckOpen,
    CheckResult,
    navigate,
    toggleOnLoad,
  ]);

  async function onLoad() {
    if (writeOffDocumentId) {
      await DocumentsService.getDocMarksList(
        writeOffDocumentId,
        pagination?.pageIndex + 1,
        pagination.pageSize,
      )
        .then((response) => {
          if (response) {
            setTableData({
              values: response?.products,
              currentPage: response?.currentPage,
              itemsPerPage: response?.itemsPerPage,
              totalItems: response?.totalItems,
              totalPages: response?.totalPages,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching marks from document:", error),
            setTableData({
              values: [],
              currentPage: 0,
              itemsPerPage: 0,
              totalItems: 0,
              totalPages: 0,
            });
        });
      await DocumentsService.getDocument(writeOffDocumentId)
        .then((documentResponse) => {
          const { type, label } = getStatusType(documentResponse.data.status, t);
          setStatusType({ type, label });
          setInitialValues(documentResponse.data);
          setStatus(documentResponse.data.status);

          if (documentResponse.data.isAddingStarted) {
            const repeatRequest = async () => {
              const resp = await DocumentsService.getDocument(writeOffDocumentId);
              setInitialValues(resp.data);
      
              if (resp.data.isAddingStarted && location.pathname.includes("inventory-write-off")) {
                setTimeout(repeatRequest, 7000);
              } else if (!resp.data.isAddingStarted && location.pathname.includes("inventory-write-off")) {
                onLoad();
              }
            };
      
            repeatRequest(); 
          }

        })
        .catch((error) => {
          navigate("/404");
        });
    }
  }

  async function submitCreate() {
    const backendData = {
      ...documentState.documentData,
      refDocType: 2,
      id: writeOffDocumentId || null,
    };
    if (writeOffDocumentId && documentState.isSafe) {
      await DocumentsService.editDocument(backendData)
        .then((response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          dispatch(deleteDataDocument());
          onLoad();
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_edited_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_edit_error"),
          });
        });
    }
    if (!writeOffDocumentId) {
      const create = await createDocument(backendData)
        .then((response) => {
          dispatch(deleteDataDocument());
          navigate(
            `/documents/inventory-write-off/${response.data.document.id}/orders`,
          );
          dispatch(clearBlocker())
          setIsFormChanged(false);
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_saved_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_save_error"),
          });
        });
    }
  }

  const closeModal = () => {
    setResModal(false)
  }

  const getDocument = async () => {
    try {
      if (writeOffDocumentId) {
        setIsLoading(true);
        const fileBlob = await DocumentsService.downloadDocumentMarks(writeOffDocumentId);
        const downloadUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${initialValues?.docNumber}.xlsx`);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      }
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Это ЗАПРОС НА ФАЙЫ ДОКА!
  const handleDownloadClick = async () => {
    try {
      setIsLoading(true);
      const fileBlob = await viewAndPrintDocument(writeOffDocumentId);
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `document_${writeOffDocumentId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkboxDelete = (id: any) => {
    setDeleteMarks((prevDeleteMarks: any) => {
      if (prevDeleteMarks.includes(id)) {
        return prevDeleteMarks.filter((item: any) => item !== id);
      } else {
        return [...prevDeleteMarks, id];
      }
    });
  };

  const fullDeleteMarks = () => {
    setDeleteMarks([]);
    if (tableData?.values) {
      const allIds = tableData.values.map((value: any) => value?.markId);
      setDeleteMarks(allIds);
    }
    if (deleteMarks?.length === tableData?.values?.length) {
      setDeleteMarks([]);
    }
  };

  const handleDelete = (docId?: string, marks?: string[]) => {
    if (typeof docId !== undefined && marks) {
      DocumentsService.deleteMarks(docId, marks)
        .then((response) => {
          if (
            tableData?.currentPage === tableData?.totalPages &&
            deleteMarks?.length === tableData?.values?.length
          ) {
            setPagination((prevState) => ({
              ...prevState,
              pageIndex:
                prevState.pageIndex === 0
                  ? prevState.pageIndex
                  : prevState.pageIndex - 1,
            }));
          }
          setDeleteMarks([]);
        })
        .catch((error) =>
          console.log(`Error deleting mark from document:" ${error}`),
        )
        .finally(() => {
          setIsDeleteModalOpen(null);
          onLoad();
        });
    } else {
      setErrorModal(t("documentNotification.no_id_error"));
      setIsDeleteModalOpen(null);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue = t("documentNotification.unsaved_changes_warning");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormChanged]);
  const { isBlocked, proceed } = useAppSelector((state) => state.blocker);
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked !== false && // и если форма изменена

      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes("inventory-write-off"),
  );

  if (isLoading && !role) return <Loader />;

  if (!role || !role?.roleAccepted?.view) {
    return (
      <PageNotRole />
    )
  }
  const navigateTo = 'sent'

  return (
    <div className="max-w-[1585px] w-full m-t-[20px]">
      {isBlocked && blocker.state === "blocked" ? (
        <ModalComponent
          isOpen={true} // Модальное окно всегда открыто, когда blocker.state === "blocked"
          onClose={() => {
            if (blocker && typeof blocker.reset === "function") {
              blocker.reset();
            } else {
              console.error("Reset function is not available.");
            }
          }}
          headerContent={
            <>
              <span>{t("documentNotification.notification")}</span>
              <button
                type="button"
                onClick={() => {
                  if (blocker && typeof blocker.reset === "function") {
                    blocker.reset();
                  } else {
                    console.error("Reset function is not available.");
                  }
                }}
                aria-label="Close"
                className="float-right cursor-pointer bg-none border-none"
              >
                &times; {/* Символ крестика */}
              </button>
            </>
          }
          content={<p>{t("documentNotification.unsaved_changes_warning")}</p>}
          customContent={
            <>
              <ModalButton
                type="button"
                onClick={() => {
                  dispatch(clearBlocker())
                  if (blocker && typeof blocker.proceed === "function") {
                    blocker.proceed(); // Уходим со страницы без сохранения
                  } else {
                    console.error("Proceed function is not available.");
                  }
                }}
              >
                {t("mainButtons.cancel")}
              </ModalButton>
              <ModalButton
                type="button"
                color="green"
                onClick={async () => {
                  dispatch(clearBlocker())
                  try {
                    await submitCreate(); // Сохранение данных
                    if (blocker && typeof blocker.proceed === "function") {
                      blocker.proceed(); // Переход после сохранения
                    }
                  } catch (error) {
                    console.error("Error saving data:", error);
                    // Можно обработать ошибку, например, показать уведомление пользователю
                  }
                }}
              >
                {t("mainButtons.save")}
              </ModalButton>
            </>
          }
        />
      ) : null}
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
          <div>
            {writeOffDocumentId ? (
              <div className="flex gap-[20px]">
                <div className="flex flex-col">
                  <StatusBadge type={statusType.type}>
                    {t("documentLabels.status") + ": " + statusType.label}
                  </StatusBadge>
                  {(initialValues?.signingAt || initialValues?.createdAt) && (
                    <div className="text-[14px] font-semibold text-gray-700 ml-[9px]">
                      {format(
                        new Date(
                          initialValues?.signingAt || initialValues?.createdAt,
                        ),
                        "dd.MM.yyyy, HH:mm",
                      )}
                    </div>
                  )}
                </div>
                <span className="flex gap-[10px]">
                  <div>{getDescription(initialValues?.refDocType, t)}</div>
                  <div>
                    {initialValues.docNumber
                      ? initialValues.docNumber
                      : " " + t("mainLabels.not_generated")}
                  </div>
                </span>
              </div>
            ) : (
              <div className="flex gap-[20px]">
                <StatusBadge type={statusType.type}>
                  {t("documentLabels.status") + " " + statusType.label}
                </StatusBadge>
                <div>
                  {t("documentLabels.create_document")}
                  {": "}
                  {t("documentType.withdrawal_notification")}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-between ">
          <div className="my-auto">
            <Stepper currentStep={2} />
          </div>

          <div className="flex flex-row gap-[20px] flex-wrap">
            {writeOffDocumentId && status === "PROCESSED" &&
              tableData?.totalItems &&
              tableData?.totalItems > 0 &&
              role.roleDoc.actions[0].active ? (
              <button
                className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                type="button"
                onClick={() => getDocument()}
              >
                {t("documentLabels_3.download_marks")}
              </button>
            ) : null}
            <button
              className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => {
                dispatch(deleteDataDocument());
                if (initialValues.status == "DRAFT") {
                  console.log("Navigating to drafts");
                  navigate("/documents/drafts");
                } else {
                  navigate("/documents/sent")
                }
              }}
            >
              {t("mainButtons.close")}
            </button>
            <button
              className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
              type="button"
              onClick={() =>
                writeOffDocumentId
                  ? navigate(
                    `/documents/inventory-write-off/${writeOffDocumentId}`,
                  )
                  : navigate(`/documents/inventory-write-off/new`)
              }
            >
              {t("mainButtons.back")}
            </button>
            {((status === "new" && role.roleDraft.edit) ||
              (status == "DRAFT" && role.roleDraft.edit)) && (
                <div className="flex flex-row gap-[20px] flex-wrap">
                  <button
                    className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={() => submitCreate()}
                  >
                    {t("mainButtons.save")}
                  </button>
                </div>
              )}
            {(status == "DRAFT" && role.roleDraft.specificAction.actions[0].active) && (
              <div className="flex flex-row gap-[20px] flex-wrap">
                <button
                  className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                  type="button"
                  onClick={() =>
                    submitXmlProduct(
                      writeOffDocumentId,
                      navigate,
                      setAlertOpen,
                      setIsLoading,
                      setErrorModal,
                      setIsCheckOpen,
                      t,
                      navigateTo
                    )
                  }
                >
                  {t("mainButtons.sign")}
                </button>
              </div>
            )}
            {status !== "DRAFT" && status !== "new" && status !== "ERROR" && status !== "IN_PROCESSING" && role.roleDoc.actions[1].active && (
              <div className="flex flex-row gap-[20px] flex-wrap">
                <button
                  className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                  type="button"
                  onClick={() => handleDownloadClick()}
                >
                  {t("mainButtons.print")}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col bg-white  my-3 px-5 pb-3 relative rounded-xl shadow-md  gap-[20px]">
          <div className=" my-5 relative flex flex-row align-center gap-x-[20px]">
            {((status === "new" && role.roleDraft.edit) ||
              (status == "DRAFT" && role.roleDraft.edit)) && (
                <>
                  <button
                    onClick={() => {
                      dispatch(deleteDataDocument());
                      submitCreate();
                    }}
                    className=" bg-green text-white font-semibold py-2 px-4 rounded"
                    data-tooltip-offset={4}
                    data-tooltip-id="document-type"
                  >
                    {t("documents_orders.add_products")}
                  </button>
                  {status !== "new" &&
                    <button
                      onClick={() =>
                        setResModal(true)
                      }
                      disabled={initialValues.isAddingStarted}
                      className="bg-green text-white font-semibold py-2 px-4 rounded flex items-center justify-center"
                    >
                      {initialValues.isAddingStarted ? (
                        <div className="flex items-center gap-[10px]">
                          <span className="text-white">{t("documents_upload.checkResult")}</span>
                          <div className={style.loaderContainer}>
                            <div className={style.loader}></div>
                          </div>
                        </div>
                      ) : (
                        <span className="text-white">{t("documents_upload.checkResult")}</span>
                      )}
                    </button>
                  }
                  {deleteMarks?.length > 0 && (
                    <button
                      onClick={() => setIsDeleteModalOpen(deleteMarks)}
                      className=" bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                    >
                      {t("documents_orders.delete_products")}
                    </button>
                  )}
                  <div className="z-[1000] relative	">
                    <Tooltip
                      id="document-type"
                      className={classNames(
                        style.wrapperTooltip,
                        "drop-shadow-2xl",
                      )}
                      openOnClick={true}
                      clickable={true}
                      noArrow={true}
                      opacity={1}
                      place={"bottom-end"}
                    >
                      <div
                        className={classNames(
                          style.tooltip,
                          "flex flex-col min-w-[208px] p-0 rounded-lg relative left-[21px] overflow-hidden",
                        )}
                      >
                        <button
                          type="button"
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsFileUploadModalOpen(true)}
                        >
                          <span>{t("documents_orders.upload_from_file")}</span>
                        </button>
                        <button
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsDocumentSearchModalOpen(true)}
                        >
                          <span>{t("documents_orders.add_codes")}</span>
                        </button>
                        <button
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsMarkScanModal(true)}
                        >
                          <span>{t("mark_scan.scan_codes")}</span>
                        </button>
                      </div>
                    </Tooltip>
                  </div>
                </>
              )}
          </div>
          <div className="w-full">
            <div className="rounded-lg  overflow-x-auto bg-white">
              <table className="max-w-full w-full leading-normal">
                <thead className="border-b-2 border-gray-300">
                  <tr>
                    {(status === "new" ||
                      (status === "DRAFT" && tableData?.totalItems !== 0)) && (
                        <th
                          className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                          scope="col"
                        >
                          <CheckBox
                            checked={
                              deleteMarks?.length === tableData?.values?.length
                            }
                            onChange={() => {
                              fullDeleteMarks();
                            }}
                          />
                        </th>
                      )}
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      № {t("documents_orders.number")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.code")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.ntin")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.order_number")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.product_name")}
                    </th>
                  </tr>
                </thead>
                {tableData?.values?.length === 0 || !tableData?.values ? (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center py-5">
                        <div>
                          <EmptyListPlaceholderContent
                            title={t("documents_orders.no_data_title")}
                            description={t(
                              "documents_orders.no_data_description",
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="font-normal text-center">
                    {tableData?.values?.map((value: any, index: any) => (
                      <tr key={`tr-${index}-${value.id}`}>
                        {((status === "new" && role.roleDraft.edit) ||
                          (status == "DRAFT" && role.roleDraft.edit)) && (
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <CheckBox
                                id="myCheckbox"
                                checked={deleteMarks.includes(value?.markId)}
                                onChange={() => checkboxDelete(value?.markId)}
                              />
                            </td>
                          )}
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {index + 1 || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.markCode || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.productTypeCode || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.orderNumber || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.productName || "-"}
                        </td>
                        {((status === "new" && role.roleDraft.edit) ||
                          (status == "DRAFT" && role.roleDraft.edit)) && (
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <img
                                src="/icons/trash.svg"
                                className="cursor-pointer min-w-[20px]"
                                onClick={() =>
                                  setIsDeleteModalOpen([value?.markId])
                                }
                              ></img>
                            </td>
                          )}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            {deleteMarks.length > 0
              ?
              <div className="pt-5 px-5 text-gray-600 font-medium">
                {t("documents_upload.selectedCount", {
                  selectedCount: deleteMarks.length,
                  totalItems: tableData?.totalItems || 0,
                })}

              </div>
              :
              <div className="pt-5 px-5 text-gray-600 font-medium">
                {t("documents_upload.selectedCount", {
                  selectedCount: 0,
                  totalItems: tableData?.totalItems || 0,
                })}

              </div>
            }
            <Pagination
              totalItems={tableData?.totalItems || 0}
              totalPages={tableData?.totalPages || 0}
              pagination={pagination}
              setPagination={setPagination}
            />
          </div>
          <ModalComponent
            isOpen={!!isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(null)}
            headerContent={
              <h1 className="h__modal">
                {t("documentNotification.notification")}
              </h1>
            }
            content={
              <p className="p__modal">{t("documents_orders.confirm_delete")}</p>
            }
            customContent={
              <div className="flex flex-row gap-[10px]">
                <ModalButton
                  type="button"
                  color="grey"
                  onClick={() => setIsDeleteModalOpen(null)}
                >
                  {t("mainButtons.cancel")}
                </ModalButton>
                <ModalButton
                  type="button"
                  onClick={() => {
                    isDeleteModalOpen &&
                      handleDelete(writeOffDocumentId, isDeleteModalOpen);
                    setIsDeleteModalOpen(null);
                    onLoad();
                  }}
                >
                  {t("mainButtons.delete")}
                </ModalButton>
              </div>
            }
          />
          <ModalComponent
            isOpen={errorModal && !errorModal.singleError}
            onClose={() => setErrorModal(null)}
            headerContent={
              <h1 className="h__modal">
                {t("documentNotification.notification")} -{" "}
                {t("documentNotification.check_fields")}:
              </h1>
            }
            content={
              <div className="error-list">
                <ul>
                  {errorModal && errorModal?.length > 0 ? (
                    errorModal.map((error: any, index: any) => (
                      <li className="border-b border-gray-300 py-1" key={index}>
                        {t(`error_dictionary.${error}`)}
                      </li>
                    ))
                  ) : (
                    <li>{t("documentNotification.no_errors")}</li>
                  )}
                </ul>
              </div>
            }
            customContent={
              <ModalButton
                type="button"
                onClick={() => {
                  setErrorModal(null);
                }}
              >
                {t("mainButtons.accept")}
              </ModalButton>
            }
          />
          <ModalComponent
            isOpen={errorModal?.singleError}
            onClose={() => setErrorModal(null)}
            headerContent={
              <h1 className="h__modal">
                {" "}
                {t("documentNotification.notification")}
              </h1>
            }
            content={
              <div className="error-list">
                <p className="border-b border-gray-300 py-1">
                  {t("documentStatus.error")}: {errorModal?.errorMessage}
                </p>
              </div>
            }
            customContent={
              <ModalButton
                type="button"
                onClick={() => {
                  setErrorModal(null);
                }}
              >
                {t("mainButtons.accept")}
              </ModalButton>
            }
          />
          <ModalResult
            documentId={writeOffDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
            setErrorModal={setErrorModal}
            resModal={resModal}
            closeModal={closeModal}
          />
          <DocumentsSearch
            isOpen={isDocumentSearchModalOpen}
            setIsOpen={setIsDocumentSearchModalOpen}
            documentId={writeOffDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <DocumentUpload
            isOpen={isFileUploadModalOpen}
            setIsOpen={setIsFileUploadModalOpen}
            documentId={writeOffDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <MarkScan
            isOpen={isMarkScanModal}
            setIsOpen={setIsMarkScanModal}
            documentId={writeOffDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <CheckResult
            isOpen={isCheckOpen}
            setIsOpen={setIsCheckOpen}
            problemMarks={isCheckOpen}
            docId={writeOffDocumentId}
          />
          {isLoading && <Loader />}
          <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
        </div>
      </div>
    </div>
  );
}

export default DocumentInventoryWriteOffOrders;
