export function createXmlString(value: any, nodeName: string = "registerInfo"): string {
  if (typeof value === "object" && !Array.isArray(value)) {
    // Если значение — объект
    const children = Object.entries(value)
      .map(([key, val]) => createXmlString(val, key))
      .join("");
    return `<${nodeName}>${children}</${nodeName}>`;
  } else if (Array.isArray(value)) {
    // Обработка массива selectRole
    if (nodeName === "selectRole") {
      return value
        .map((roleId) => `<${nodeName}><roleId>${roleId}</roleId></${nodeName}>`)
        .join("");
    }
    // Если значение — массив, преобразуем в JSON-строку
    return `<${nodeName}>${JSON.stringify(value)}</${nodeName}>`;
  } else {
    // Если значение — строка или пустое
    return `<${nodeName}>${value || ""}</${nodeName}>`;
  }
}
