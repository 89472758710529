import axios, { AxiosResponse } from "axios";
import { GetResponse, PostResponse } from "./response";
import { DocsType } from "../../pages/DocumentList/DocumentList";
import i18n from "../../i18n";
import apiClient from "./apiClient";
interface OrderProduct {
  id: number;
  productTypeCode: string;
  weight: number;
  size: number;
  amount: number;
  orderId: number;
}

interface OrderRequest {
  status: string;
  refReleaseMethod: number;
  refCountryOrigin: number;
  note: string;
  orderProducts: OrderProduct[];
}

interface LooseObject {
  [key: string]: any;
}

export const getOrdersList = async (
  page: number = 1,
  size: number = 10,
  sortDirection: "ASC" | "DESC" = "DESC",
  startDate?: string,
  endDate?: string,
  number?: string,
  status?: string[],
  refReleaseMethod?: string,
  orgIinBin? : any,
  marksAmount?: number,

): Promise<any> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      sortDirection,
    }).toString();

    const requestBody: LooseObject = {};

    if (startDate) {
      requestBody.creationDateStart = startDate;
    }
    if (endDate) {
      requestBody.creationDateEnd = endDate;
    }
    if (orgIinBin) {
      requestBody.orgIinBin = orgIinBin;
    }
    if (number) {
      requestBody.number = number;
    }

    if (status && status?.length !== 0) {
      requestBody.status = status;
    }

    if (refReleaseMethod) {
      requestBody.refReleaseMethod = [refReleaseMethod];
    }

    if (marksAmount) {
      requestBody.marksAmount = marksAmount;
    }

    const response = await apiClient.post(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/list?${params.toString()}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении списка заказов:", error);
    throw error;
  }
};

export const GetReferenceService = async (endpoint: string): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse> = await apiClient.get(
      `${process.env.REACT_APP_URL_REFERENCE_SERVICE}/reference/${endpoint}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const createOrder = async (orderData: OrderRequest): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await apiClient.post(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/create`,
      orderData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during order creation:", error);
    throw error;
  }
};

export const searchOrderById = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await apiClient.get(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/search-by-id/${id}`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при поиске заказа:", error);
    throw error;
  }
};

export const getOrderProducts = async (
  orderId: any,
  page: number = 1, // Номер страницы, по умолчанию 1
  size: number = 300000, // Размер страницы, по умолчанию 10
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await apiClient.get(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/product/list/${orderId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении списка изделий заказа:", error);
    throw error;
  }
};
export const deleteOrder = async (orderId: any) => {
  try {
    const response = await apiClient.delete(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/delete/${orderId}`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при удалении заказа:", error);
    throw error;
  }
};

export const deleteProducts = async (
  orderId: any,
  productIds: number[],
): Promise<any> => {
  try {
    const response = await apiClient.delete(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/product/delete/${orderId}`,
      {
        data: {
          productIds: productIds,
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при удалении изделий из заказа:", error);
    throw error;
  }
};

export const addProducts = async (
  orderId: any,
  products: any[],
): Promise<any> => {
  try {
    const response = await apiClient.post(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/product/create-v2/${orderId}`,
      {
        products: products,
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при удалении изделий из заказа:", error);
    throw error;
  }
};

export const editProducts = async (
  orderId: any,
  products: any[],
): Promise<any> => {
  try {
    const response = await apiClient.put(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/product/edit-v2/${orderId}`,
      {
        products: products,
      },
      {
        withCredentials: true,
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при редактировании изделий заказа:", error);
    throw error;
  }
};

export const editOrder = async (orderId: any, orderData: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await apiClient.put(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/edit/${orderId}`,
      orderData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during order editing:", error);
    throw error;
  }
};

export const downloadOrderFile = async (orderId: any, fileType: any) => {
  try {
    const response = await apiClient.get(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/download/${orderId}/${fileType}`,
      {
        withCredentials: true,
        responseType: "blob", // Важно для скачивания файла
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при скачивании файла:", error);
    throw error;
  }
};

export const CheckMarks = async (
  orderIds: number[] | string[],
  page: number,
  size: number,
  sortDirection: string,
): Promise<any> => {
  const params = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    sortDirection,
  }).toString();

  try {
    const response = await apiClient.post(
      `${process.env.REACT_APP_URL_ORDERS_SERVICE}/orders/check-marks?${params.toString()}`,
      {
        orderIds: orderIds,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при запросе проверки заказа и марок:", error);
    throw error;
  }
};

export const getNtinCodes =async()=>{
  try {
    const response: AxiosResponse<any> = await apiClient.get(
      `${process.env.REACT_APP_URL_REFERENCE_SERVICE}/reference/app/jewelry-groups/list`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении списка изделий заказа:", error);
    throw error;
  }
}
