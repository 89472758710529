import React, { useState } from "react";

import SubMenu from "./SubMenu";
import { logout } from "../../slice/authSlice";
import { useAppDispatch, useAppSelector } from "../../hook";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { logoutUser } from "../../../common/api/authApi";
import { useTranslation } from "react-i18next";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import { deleteRoleState } from "../../slice/roleMatrix";
import { roleMatrix } from './../../slice/roleMatrix';
import ModalComponent from "../../Ui/ModalComponent/ModalWindow";
import ModalButton from "../../Ui/Buttons/ModalButtons";
import { setBlocker, clearBlocker } from '../../../shared/slice/blockerSlice'
function SideNavbar() {
  const [open, setOpen] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);
  const roleMatrix=useAppSelector(state=>state.roleMatrix);
  const { isBlocked, proceed } = useAppSelector((state) => state.blocker);
  const [errorModal,setErrorModal] = useState(false)
  
  const Menus = [
    {
      title: t("submenu.mainPage"),
      icon: "/icons/home.svg",
      route: "/main",
      roleId:1,
    },
    {
      title: t("submenu.orders"),
      icon: "/icons/datamatrix.svg",
      route: "/orders",
      roleId:2,
    }, 
    {
      title: t("submenu.documents"),
      icon: "/icons/document.svg",
      roleId:3,
      SubMenuItems: [
        { title: t("submenu.allDocs"), route: "/documents/all", roleId:12 },
        { title: t("submenu.inbox"), route: "/documents/inbox", roleId:4 },
        { title: t("submenu.sent"), route: "/documents/sent", roleId:5 },
        { title: t("submenu.drafts"), route: "/documents/drafts", roleId:6 },
        { title: t("submenu.personalization"), route: "/documents/personalization", roleId:7 },
      ],
    },
    {
      title:t("submenu.products_catalog"),
      icon: "/icons/productCatalog.svg",
      roleId:8,
      SubMenuItems:[
        {title:t("submenu.my_cards"),route:"/products-catalog/my-cards", roleId:9},
        {title:t("submenu.all_cards"),route:"/products-catalog/all-cards", roleId:10},
      ]
    },
    {
      title:t("submenu.receipts"),
      icon: "/icons/document.svg",
      route: "/chequesList",
      roleId:11,
    }
    // { title: t("submenu.reports"), icon: "/icons/piechart.svg", route: "" },
  ];

  const Logout = { title: t("submenu.logout"), icon: "/icons/exit.svg" };

  // Функция для выхода пользователя
  const handleLogout = async () => {
    if(!isBlocked){
    const refreshToken = Cookies.get("refresh_token");
    console.log(refreshToken);
    if (refreshToken) {
      try {
        await logoutUser(refreshToken);
        dispatch(deleteRoleState);
        dispatch(logout());
        navigate("/authorization");
      } catch (error) {
        dispatch(deleteRoleState);
        dispatch(logout());
        navigate('/authorization');
      }
    } else {
      // Если refreshToken не найден, просто диспатчим действие logout
      dispatch(deleteRoleState);
      dispatch(logout());
      navigate("/authorization");
    }
    }else{
setErrorModal(true)
      console.log('На странице есть изменения');
    }
    
  };

  return (
    
    <nav
      className={` ${
        open ? "w-72" : "w-20 "
      } overflow-hidden bg-white  p-5  pt-8 relative duration-300 rounded shadow-md h-full flex flex-col`}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex gap-x-4 items-center">
          <img
            src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
            alt="E-Track Logo"
            className={` h-12 pl-2 origin-left font-medium text-xl duration-200 ${
              !open && "scale-0"
            }`}
          />
        </div>
        <img
          src="/icons/double-arrow-left.svg"
          className={`cursor-pointer  p-1 w-7 h-7 bg-slate-300
         rounded-full  ${!open && "rotate-180"}`}
          onClick={() => setOpen(!open)}
        />
      </div>

      <ul className="pt-6 h-full m-0 flex flex-col">
      {Menus.map((menu, index) => {
              const menuRole = roleMatrix.roleMatrix.find(area => menu.roleId === area.id);
              if (menuRole && menuRole.view) {
                  return (
                      <SubMenu
                          key={`submenu-${index}-0-${menu.title}`}
                          index={index}
                          icon={menu.icon}
                          title={menu.title}
                          route={menu.route || ""}
                          open={open}
                          subMenuItems={menu.SubMenuItems?.filter(subMenu => {
                              const subMenuRole = roleMatrix.roleMatrix.find(area => subMenu.roleId === area.id);
                              return subMenuRole && subMenuRole.view;
                          })}
                      />
                  );
              }
              return null;
          })}
        <li className="mt-auto">
          <button
            key={`meni-item-logout`}
            className="w-full group flex rounded-md p-2 cursor-pointer hover:bg-light-white items-center gap-x-4 mt-2 bg-slate-100
              hover:bg-green hover:text-white"
            onClick={handleLogout}
          >
            <img
              src={Logout.icon}
              className="block h-6 w-6 duration-200 group-hover:brightness-200 group-hover:invert-1"
            />
            <span className={`${!open && "hidden"} origin-left duration-200`}>
              {Logout.title}
            </span>
          </button>
        </li>
      </ul>
      {errorModal && (
        <ModalComponent
          isOpen={errorModal}
          onClose={() => {
            setErrorModal(false);
          }}
          headerContent={t('orderList.notification')}
          content={
            <p>
                {" "}
                Сохраните изменения, прежде чем завершить сессию
              </p>
          }
          customContent={
            <div className="flex gap-[15px]">
              <ModalButton
              type="button"
              onClick={async () => {
                dispatch(clearBlocker())
                setErrorModal(false);
                const refreshToken = Cookies.get("refresh_token");
                console.log(refreshToken);
                if (refreshToken) {
                  try {
                    await logoutUser(refreshToken);
                    dispatch(deleteRoleState);
                    dispatch(logout());
                    navigate("/authorization");
                  } catch (error) {
                    dispatch(deleteRoleState);
                    dispatch(logout());
                    navigate('/authorization');
                  }
                } else {
                  // Если refreshToken не найден, просто диспатчим действие logout
                  dispatch(deleteRoleState);
                  dispatch(logout());
                  navigate("/authorization");
                }
              }}
            >
              Не сохранять
            </ModalButton>
            <ModalButton
              color='green'
              type="button"
              onClick={() => {
                setErrorModal(false);
              }}
            >
              {t("mainButtons.accept")}
            </ModalButton>
            </div>
            
            
          }
        />
      )}
    </nav>
  );
}

export default SideNavbar;
