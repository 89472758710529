import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../shared/hook";
import { downloadUserFiles, userFiles } from "../../common/api/authApi";
import { format } from "date-fns";
import { FaInfoCircle } from "react-icons/fa";
// import {styles} from "./ProfileCard.module.css"
import style from "./ProfileCard.module.css";
import { normalizedLocale } from "../../common/libs/normalizedLocale";
import { SkeletonTheme } from "react-loading-skeleton";
import MockSkeleton from "../OrderList/utils/MockSkeleton";
import { EmptyListPlaceholderContent } from "../../shared/Ui/EmptyPageBlock";
import StatusBadge from "../../shared/Ui/StatusBadge/StatusBadge";
import { useNavigate } from "react-router-dom";
import { getBalance, getClientData, regBalance } from "../../common/api/asrApi";
import ModalComponent from "../../shared/Ui/ModalComponent/ModalWindow";
import ModalButton from "../../shared/Ui/Buttons/ModalButtons";


function ProfileCard() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<any>({
    values: [],
    totalItems: 0,
    totalPages: 0,
  });
  const navigate = useNavigate();
  const [errorModal, setErrorModal] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({
    balance: '',
    customerId: '',
    iinBin: '',
    nameRu: '',
    nameKk: '',
    providerId: 0,
    createdAt: null,
    updatedAt: null,
    error: '',
    agreements: [
      {
        agreementId: 0,
        number: '',
        createdAt: null,
        updatedAt: null,
        error: '',
        accounts: [
          {
            accountId: 0,
            createdAt: null,
            updatedAt: null,
            error: ''
          }
        ]
      }
    ]
  });
  const [page, setPage] = useState<number>(1);
  const [userFilesData, setUserFilesData] = useState([]);
  const userData: any = useAppSelector((state) => state.auth.userData);
  const locale = normalizedLocale(i18n.language);
  type PaginationState = {
    pageIndex: number;
    pageSize: number;
  };
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [pagination, setPagination] = useState<PaginationState>(() => {
    const savedPagination = localStorage.getItem('paginationProfile');
    return savedPagination ? JSON.parse(savedPagination) : { pageIndex: 0, pageSize: pageSize };
  });

  useEffect(() => {
    onLoad()
    localStorage.setItem('paginationProfile', JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const fetchUserFiles = async () => {
      try {
        const result = await userFiles();
        setUserFilesData(result.data);
      } catch (error) {
        console.error("Error fetching user files:", error);
      }
    };

    fetchUserFiles();
  }, [page]);

  async function onLoad() {
    try {
      const updateDate = format(new Date(), "dd.MM.yyyy, HH:mm:ss");
      console.log(123);
  
      let balance = null;
      let orderResponse = null;
  
      try {
        balance = await getBalance();
      } catch (error:any) {
        console.log('error',error);
        
        setErrorModal(error.response.data.message);
      }
  
      try {
        orderResponse = await getClientData();
      } catch (error) {
        console.error("Ошибка при получении данных клиента:", error);
      }
  
      if (orderResponse?.error) {
        setErrorModal(orderResponse.error);
      }
  
      setInitialValues({ ...orderResponse, balance: balance, updateDate: updateDate });
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    }
  }

  const calculateItemNumber = (index: any) => {
    return pagination.pageIndex * pagination.pageSize + index + 1;
  };

  interface StatusType {
    type: string;
    label: string;
  }
  const statusTypes: { [key: string]: StatusType } = {
    DRAFT: { type: "test", label: t("documentStatus.draft") },
    ERROR: { type: "error", label: t("documentStatus.error") },
    IN_PROCESSING: { type: "test", label: t("documentStatus.in_processing") },
    PROCESSED: { type: "active", label: t("documentStatus.processed") },
    IN_ACCEPTANCE: { type: "test", label: t("documentStatus.in_acceptance") },
    IN_CONFIRMATION: {
      type: "test",
      label: t("documentStatus.in_confirmation"),
    },
    WITHDRAWN: { type: "error", label: t("documentStatus.withdrawn") },
    REJECTED: { type: "error", label: t("documentStatus.rejected") },
    SIGNED_BY_RECIPIENT: {
      type: "active",
      label: t("documentStatus.signed_by_recipient"),
    },
    NOT_PROCESSED: { type: "error", label: t("documentStatus.not_processed") }
  };

  // Это ЗАПРОС НА ФАЙЫ ДОКА DOWLOAD!
  const handleDownloadClick = async (filename: any, index: any) => {
    try {
      // setIsLoading(true);
      const fileBlob = await downloadUserFiles(filename);
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${index === 0
        ? "Заявление о присоединении"
        : "Договор доступа к услугам"}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl); // освободите ресурсы, связанные с URL
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    }
  };

  const hasRoleOperator = Array.isArray(userData.selectRoleV2) && userData.selectRoleV2.some((item: any) => item.roleId === 5);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(formik) => (
        <div className="w-full flex flex-col gap-[40px] font-['Inter']">
          <div className="flex flex-row gap-[15px] cursor-pointer text-[17px]">
            <p className={`cursor-pointer relative ${page === 1 ? 'text-green after:content-[""] after:absolute after:left-0 after:bottom-[-2px] after:w-full after:h-[2px] after:bg-green' : ''}`} onClick={() => setPage(1)}>{t('accountDetails.organizationInfo')}</p>
            <p className={`cursor-pointer relative ${page === 2 ? 'text-green after:content-[""] after:absolute after:left-0 after:bottom-[-2px] after:w-full after:h-[2px] after:bg-green' : ''}`} onClick={() => setPage(2)}>{t('accountDetails.accountNumber')}</p>
            {/* <p className={`cursor-pointer relative ${page === 3 ? 'text-green after:content-[""] after:absolute after:left-0 after:bottom-[-2px] after:w-full after:h-[2px] after:bg-green' : ''}`} onClick={() => setPage(3)}>{t('accountDetails.organizationEmployees')}</p> */}
          </div>
          {page == 1 && (
            <>
              <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] gap-[30px] max-w-[1200px]">
                <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
                  <img src="assets/contactData.png" className="w-[20px]" />
                  <div className="uppercase">{t("profile_card.contacts")}</div>
                </div>
                <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.name")}
                    <p className="font-light">
                      {userData.userName} {userData.userSurname}{" "}
                      {userData.userPatronymic}
                    </p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.iin")}
                    <p className=" font-light">{userData.userIIN}</p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.phone")}
                    <p className="text-gray-600 font-light">{userData.phone}</p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.email")}
                    <p className="text-gray-600 font-light">{userData.email}</p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.postion")}
                    <p className="text-gray-600 font-light">
                      {locale == "ru" ? userData.userPositionRu : userData.userPositionKz}


                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] gap-[30px] max-w-[1200px]">
                <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
                  <img src="assets/orgData.png" className="w-[20px]" />
                  <div className="uppercase">
                    {t("profile_card.organization_title")}
                  </div>
                </div>
                <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.organization_name")}
                    <p className="text-gray-600 font-light">
                      {userData.organizationName}
                    </p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.organization_iin_bin")}

                    <p className="text-gray-600 font-light">{userData.iinBin}</p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.organization_address")}

                    <p className="text-gray-600 font-light">
                      {`${userData.address.city || ""}${userData.address.city ? "," : ""} 
    ${userData.address.street || ""}${userData.address.street ? "," : ""} 
    ${userData.address.house || ""} 
    ${userData.address.building ? userData.address.building : ""}
    ${userData.address.premises ? userData.address.premises : ""}`}
                    </p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.organization_website")}

                    <p className="font-light">
                      {userData.organizationWebsite
                        ? userData.organizationWebsite
                        : "-"}
                    </p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.organization_phone")}

                    <p className="font-light">{userData.organizationPhone}</p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.organization_email")}

                    <p className="font-light">{userData.organizationEmail}</p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.code_ogd")}
                    {userData.address.refOgd?.id &&
                      userData.address.refOgd?.name ? (
                      <p className="font-light">
                        {userData.address.refOgd?.name}
                      </p>
                    ) : (
                      <p className="font-light">-</p>
                    )}
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.organization_data")}

                    <p className="font-light">
                      {userData.organizationCreatedAt
                        ? format(new Date(userData.organizationCreatedAt), "dd.MM.yyyy, HH:mm")
                        : "Дата не указана"}
                    </p>
                  </div>
                </div>

              </div>

              <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] items-stretch gap-[30px] max-w-[1200px] max-h-[230px]">
                <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
                  <img src="assets/docs.png" className="w-[20px]" />
                  <div className="uppercase">
                    {t("profile_card.participation_in_the_system")}
                  </div>
                </div>

                <div className="flex gap-x-[100px] gap-y-[20px]  text-base flex-wrap">
                  <div className="flex flex-col gap-[20px]">
                    <div className="text-gray-600 w-[300px] font-semibold">
                      <div className={style.tooltipWrapper}>
                        {t('profile_card.registration_date')}
                        <FaInfoCircle className={style.errorIcon} />
                        <div className={style.tooltipText}>
                          {t('profile_card.registration_date_tooltip')}
                        </div>
                      </div>
                      {userData.createdAt ? (
                        <p className="text-gray-600 font-light">
                          {format(new Date(userData?.createdAt), 'dd.MM.yyyy')}
                        </p>
                      ) : (
                        '-'
                      )}

                    </div>
                    <div className="text-gray-600 w-[300px] font-semibold">
                      {t("profile_card.organization_product_group")}

                      <p className="font-light">
                        {userData.refProductGroup.name
                          ? userData.refProductGroup.name
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="text-gray-600 max-w-[600px] w-full font-semibold max-h-[100px]">
                    {t("profile_card.role")}

                    <div className="text-gray-600 font-light grid grid-cols-2 gap-4">
                      {userData?.selectRoleV2?.length > 0 ? (
                        userData.selectRoleV2.slice(0, 4).map((role: any, index: any) => (
                          <p key={index} className="block">
                            {role.nameRu}
                          </p>
                        ))
                      ) : (
                        <p>-</p>
                      )}
                    </div>
                  </div>

                </div>
              </div>

              <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] items-stretch gap-[30px] max-w-[1200px]">
                <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
                  <img src="assets/docs.png" className="w-[20px]" />
                  <div className="uppercase">{t("profile_card.bank_title")}</div>
                </div>
                <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.bank_name")}
                    <p className="text-gray-600 font-light">
                      {userData.refBankCatalog.name
                        ? userData.refBankCatalog.name
                        : "-"}
                    </p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.bank_identification_code")}
                    <p className="text-gray-600 font-light">
                      {userData.bankIdentificationCode}
                    </p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.bank_checking_account")}
                    <p className="text-gray-600 font-light">
                      {userData.checkingAccount}
                    </p>
                  </div>
                  <div className="text-gray-600 w-[300px] font-semibold">
                    {t("profile_card.bank_kbe")}
                    <p className="font-light">
                      {userData.kbe ? userData.kbe : "-"}
                    </p>
                  </div>
                </div>
              </div>
              {userData.userPositionRu !== "Сотрудник" && (
                <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] gap-[30px] max-w-[1200px]">
                  <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
                    <img src="assets/docs.png" className="w-[20px]" />
                    <div className="uppercase">
                      {t("profile_card.documents_title")}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center w-full gap-x-[30px]">
                    {userFilesData?.length > 0 ? (
                      userFilesData.map((doc, index) => (
                        <div className=" flex justify-between w-[400px] bg-white shadow-md rounded-2xl p-[2%]">
                          <div>
                            {index === 0
                              ? "Заявление о присоединении"
                              : "Договор доступа к услугам"}
                          </div>
                          <button
                            className="cursor-pointer min-w-6"
                            onClick={() => {
                              handleDownloadClick(doc, index);
                            }}
                          >
                            <img src="/icons/download.svg" alt="Download" />
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="flex justify-center items-center w-full h-full text-base text-gray-600 font-semibold">
                        {t("profile_card.no_documents")}!
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="pb-[2px]"></div>
            </>
          )}
          {page == 2 && (
            <>
              <div className=" max-w-[1200px] max-h-[280px] min-h-[200px] flex flex-col overflow-visible bg-white pt-[10px] p-[10px] relative rounded-xl shadow-md gap-[10px] ">
                <div className="flex justify-between pb-[5px] border-b-2 border-gray-200">
                  <div className="flex flex-col gap-[2px]">
                    <div className="flex gap-[15px] m-auto">
                      <span className=" flex items-end ">{t("accountDetails.accountNumber")}</span>

                    </div>
                  </div>
                 
                  {(!initialValues.customerId || 
  !initialValues?.agreements[0]?.agreementId || 
  !initialValues?.agreements[0]?.accounts[0]?.accountId) && (
    <button className="text-[12px] bg-green text-white font-semibold py-2 px-4 rounded max-h-[40px] mr-[10px] m-auto flex items-center"
    onClick={() => regBalance()}
    >
      {t("accountDetails.accountRegistration")}
    </button>
)}
                </div>

                <div className="flex flex-row justify-between w-full flex-wrap">
                  <div className="w-[50%] flex flex-col gap-[15px] flex-wrap">
                    <div className="flex flex-col flex-wrap">
                      <label className="font-light text-gray-600">{t("accountDetails.clientId")}</label>
                      <span>
                        {initialValues?.customerId || '-'}
                      </span>
                    </div>
                    <div className="flex flex-col flex-wrap">
                      <label className="font-light text-gray-600">{t("accountDetails.agreementId")}</label>
                      <span>
                      {initialValues?.agreements?.[0]?.agreementId || '-'}
                      </span>
                    </div>
                  </div>

                  <div className="w-[50%] flex flex-col flex-wrap gap-[15px]">
                    <div className="flex flex-col flex-wrap">
                      <label className="font-light text-gray-600">{t("accountDetails.personalAccountNumber")}</label>
                      <span>
                        {initialValues?.agreements?.[0]?.accounts?.[0]?.accountId || '-'}
                      </span>
                    </div>
                    <div className="flex flex-col ">
                      <div className="flex flex-row gap-[4px] items-center">
                        <label className="flex font-light text-gray-600">{t("accountDetails.balance")}</label>
                        <div className="text-[10px] font-light bg-[#e9f0f3] rounded-[3px] px-[4px] text-[#1e2930] ">
                          {locale == "ru" ? `на ${initialValues.updateDate}` : `${initialValues.updateDate} уақытына`}
                        </div>
                      </div>

                      <div className="flex gap-[10px]">
                        <span>
                          {initialValues.balance * -1} тг.
                        </span>
                        <div>
                          <button
                            className="flex flex-col justify-end"
                            onClick={() => {
                              onLoad()
                            }}
                          >
                            <img src="/icons/update.svg" />
                          </button>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {page == 3 && (
            <>
              <div className="rounded-lg bg-white overflow-auto max-w-[1200px]">
                <table className="max-w-full w-full leading-normal">
                  <thead className="border-b-2 border-gray-300">
                    <tr>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        №{t("documents_orders.number")}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        {t('profile_card.name')}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200 "
                        scope="col"
                      >
                        {t('profile_card.iin')}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200 "
                        scope="col"
                      >
                        {t('profile_card.postion')}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        {t('profile_card.registration_date')}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        {t('profile_card.phone')}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        {t('profile_card.email')}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        {t('documentLabels.status')}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        {t('profile_card.role')}
                      </th>

                    </tr>
                  </thead>
                  {isLoading ? (
                    <SkeletonTheme baseColor="#ebebeb" highlightColor="#c9c9c9">
                      <tbody className="font-normal text-center">
                        {new Array(10).fill(1).map((_, index) => (
                          <MockSkeleton index={index} />
                        ))}
                      </tbody>
                    </SkeletonTheme>
                  ) : tableData?.values?.length === 0 || !tableData?.values ? (
                    <tbody>
                      <tr>
                        <td colSpan={8} className="text-center py-5">
                          <div>
                            <EmptyListPlaceholderContent
                              title={t("Сотрудники отсутствуют")}
                            // description={t("orderList.noOrdersDescription")}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody className="font-normal text-center">
                      {tableData?.values?.map((value: any, index: any) => (
                        <tr
                          key={`tr-${index}-${value.id}`}
                          className="cursor-pointer hover:bg-gray-100"
                          onClick={() => {
                            console.log('navigateTo', ("/cheque/" + value?.id));
                            // {userData.selectRole[0] == "Оператор (администратор ЦРЦЭ)" ? navigate("/cheque/" + value?.id):
                            //   value.status == 'PROCESSED' ?  navigate("/cheque/" + value?.id): {}}
                            navigate("/cheque/" + value?.id)
                          }
                          }

                        >
                          <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                            {calculateItemNumber(index) || "-"}
                          </td>
                          <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600 cursor-pointer "
                          >
                            {value?.fiscalDocumentNumber || "-"}
                          </td>
                          <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                            {value?.kktRegId || "-"}
                          </td>
                          <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center w-[225px]">
                            {/* {format(value?.dateTime, "dd.MM.yyyy") || '-'} */}
                            {value.dateTime || ''}
                          </td>
                          <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center">

                            {value?.operationType == '1' ? "Чек продажи" : 'Чек возврата'}
                          </td>
                          <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center">
                            <div className="flex justify-center">
                              <StatusBadge
                                type={statusTypes[value.status]?.type || "unsaved"}
                                fontSize="15px"
                                circleSize="8px"
                              >
                                {statusTypes[value.status]?.label ||
                                  t("documents_upload.unknownStatus")}
                              </StatusBadge>
                            </div>

                          </td>
                          {value.status == 'PROCESSED' ? (
                            <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600"
                              onClick={(e) => e.stopPropagation()}
                            >

                            </td>
                          ) : (<td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600"
                            onClick={(e) => e.stopPropagation()}
                          >
                          </td>)}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </>
          )}
                 <ModalComponent
        isOpen={errorModal}
        onClose={() => {
          setErrorModal(null);
        }}
        headerContent={
          <h1 className="h__modal">
            {t("documentNotification.notification")}
          </h1>
        }
        content={
          <div className="error-list">
            <ul>
              {Array.isArray(errorModal) && errorModal.length > 0 ? (
                errorModal.map((error: any, index: number) => {
                  const errorText = typeof error === "object" ? JSON.stringify(error) : error;
                  return (
                    <li className=" border-gray-300 py-1" key={index}>
                    {t(`error_dictionary.${errorText}`)}
                    </li>
                  );
                })
              ) : typeof errorModal === "object" && errorModal !== null ? (
                errorModal.errorMessage ? (
                  <li className="border-b border-gray-300 py-1">
                    {t('documentStatus.error')}: {errorModal.errorMessage}
                  </li>
                ) : (
                  <li>{t("documentNotification.no_errors")}</li>
                )
              ) : typeof errorModal === "string" ? (
                <li className=" border-gray-300 py-1">
                  {errorModal}
                </li>
              ) : (
                <li>{t("documentNotification.no_errors")}</li>
              )}
            </ul>
          </div>
        }
        customContent={
          <ModalButton
            type="button"
            onClick={() => {
              setErrorModal(null);
            }}
          >
            {t("mainButtons.accept")}
          </ModalButton>
        }
      />
        </div>
        
      )}

    </Formik>
  );
}

export default ProfileCard;
