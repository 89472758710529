import { PaginationState } from "@tanstack/react-table";
import classNames from "classnames";
import { format } from "date-fns";
import { Field, Form, Formik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import DocumentsService from "../../../../common/api/documentsApi";
import { getOrdersList } from "../../../../common/api/ordersApi";
import Calendar from "../../../../shared/Ui/Calendar/Calendar";
import CheckBox from "../../../../shared/Ui/CheckBox/CheckBox";
import { EmptyListPlaceholderContent } from "../../../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../../../shared/Ui/ModalComponent/ModalWindow";
import StatusBadge from "../../../../shared/Ui/StatusBadge/StatusBadge";
import Pagination from "../../../../shared/components/Table/Pagination";
import { ITable } from "../../../../shared/components/Table/types";
import { useAppSelector } from "../../../../shared/hook";
import { generateAndDownloadExcel } from "../../../../shared/hooks/generateFiles";
import style from "./AddByOrderNumber.module.css";
import { addMarksByOrders } from "../../../../common/api/nktApi";
import ModalButton from "../../../../shared/Ui/Buttons/ModalButtons";
import AlertBox from "../../../../shared/Ui/AlertBox/AlertBox";
import Loader from "../../../../shared/Ui/Loader/Loader";

export interface IAddByOrderNumber {
  documentId: string | undefined;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  toggleOnLoad: boolean;
  setToggleOnLoad: Dispatch<SetStateAction<boolean>>;
  personalization?: any;
  setErrorModal?: any;
}

export const AddByOrderNumber: React.FC<IAddByOrderNumber> = (props) => {
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState<ITable>();
  const [selectedData, setSelectedData] = useState<number[]>([]);
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [errorModal, setErrorModal] = useState<any>(null);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [resultsTableData, setResultsTableData] = useState<ITable>();
  const [resultsModalOpen, setResultsModalOpen] = useState<boolean>(false);
  const [isResultsLoading, setIsResultsLoading] = useState<boolean>(false);
  const onResultsModalOpen = () => setResultsModalOpen(true);
  const onResultsModalClose = () => setResultsModalOpen(false);
  const [resultsPagination, setResultsPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [sort, setSort] = useState<"ASC" | "DESC">("DESC");

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [initialValues, setInitialValues] = useState({
    orderNumber: "",
    marksAmount: null,
  });
  const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(false);
  const [isFilterTooltipOpen, setIsFilterTooltipOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (props?.personalization?.productInfo?.productCodeId) {
      setInitialValues(prev => ({
        ...prev,
        productCodeId: props.personalization.productInfo.productCodeId
      }));
    }

    if (props.isOpen) {
      onLoad()
    };
  }, [pagination, props.isOpen, sort]);


  const onModalClose = () => props.setIsOpen(false);
  const [isBasicSearch, setIsBasicSearch] = useState<boolean>(false);

  async function onLoad() {
    setIsTableDataLoading(true);
    if (props.documentId) {
      if (
        startDate === undefined &&
        endDate === undefined &&
        initialValues?.orderNumber === "" &&
        initialValues?.marksAmount === null
      ) {
        setIsBasicSearch(true);
        await getOrdersList(
          1,
          30,
          sort,
          undefined,
          undefined,
          undefined,
          ["PROCESSED_PAID"],
          undefined,
          undefined,
        )
          .then((response) => {
            setTableData({
              values: response?.orders,
              currentPage: response?.currentPage,
              itemsPerPage: response?.itemsPerPage,
              totalItems: response?.totalItems < 30 ? response?.totalItems : 30,
              totalPages:
                response?.totalItems < 30
                  ? response?.totalPages
                  : Math.ceil(30 / pagination?.pageSize),
            });
          })
          .catch((error) => console.error("Error fetching user info:", error))
          .finally(() => setIsTableDataLoading(false));
      } else {
        setIsBasicSearch(false);
        await getOrdersList(
          pagination?.pageIndex + 1,
          pagination?.pageSize,
          sort,
          startDate && format(startDate, "dd.MM.yyyy"),
          endDate
            ? format(endDate, "dd.MM.yyyy")
            : startDate
              ? format(startDate, "dd.MM.yyyy")
              : undefined,
          initialValues?.orderNumber || undefined,
          ["PROCESSED_PAID"],
          undefined,
          initialValues?.marksAmount || undefined,
        )
          .then((response) => {
            setTableData({
              values: response?.orders,
              currentPage: response?.currentPage,
              itemsPerPage: response?.itemsPerPage,
              totalItems: response?.totalItems,
              totalPages: response?.totalPages,
            });
          })
          .catch((error) => console.error("Error fetching user info:", error))
          .finally(() => setIsTableDataLoading(false));
      }
    }
  }

  async function onLoadResults() {
    if (props.documentId) {
      try {
        const isPersonalization = !!props.personalization;
        setIsResultsLoading(true);
        const request = isPersonalization
          ? addMarksByOrders(
            props.personalization.documentId,
            props.personalization.productId,
            selectedData
          )
          : DocumentsService.addMarksByOrder(props.documentId, selectedData);

        const responseAddMarks = await request;
        setIsResultsLoading(false);
        // Показ сообщения, если есть поле message
        if (!isPersonalization) {
          setAlertOpen({
            status: responseAddMarks?.status,
            message: responseAddMarks?.message,
          });
        }

        // Проверка на наличие ошибок выполняется только если выбрана personalization
        if (props.personalization) {
          onResultsModalOpen();
          setIsResultsLoading(true);
          if (responseAddMarks?.ordersWithErrors?.length === 0) {
            setIsResultsLoading(false);
            onResultsModalClose();
            setAlertOpen({
              status: `${responseAddMarks.okMarksCount > 0
                  ? 'SUCCESS'
                  : 'error'
                }`,
              message: `${t("documents_upload.successAddMarks")}: ${responseAddMarks.okMarksCount
                }`,
            });
          } else if (responseAddMarks?.ordersWithErrors?.length > 0) {
            setResultsTableData({
              values: responseAddMarks.ordersWithErrors,
              currentPage: 0,
              itemsPerPage: responseAddMarks.totalOrdersWithErrors,
              totalItems: responseAddMarks.totalOrdersWithErrors,
              totalPages: 1,
            });
            setAlertOpen({
              status: `${responseAddMarks.okMarksCount > 0
                  ? 'SUCCESS'
                  : 'error'
                }`,
              message: `${t("documents_upload.successAddMarks")}: ${responseAddMarks.okMarksCount
                }`,
            });
          }
        }
      } catch (error: any) {
        onModalClose();
        onResultsModalClose();


        if (props.setErrorModal) {
          props.setErrorModal({
            singleError: true,
            errorMessage: error?.response?.data?.message
              ? error?.response?.data?.message
              : t('documentNotification.unknown_error'),
          });
        }
      } finally {
        setIsResultsLoading(false);
        props.setToggleOnLoad(!props.toggleOnLoad);

        // Закрываем модал, если данных для отображения нет
        if (resultsTableData?.values?.length === 0) {
          onResultsModalClose();
        }
      }
    }
  }

  const renderResultsTable = () => {
    return (
      <>
        {isResultsLoading ? (
          <div className="w-full text-center flex flex-col justify-center items-center gap-[10px]">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <span>{t("documents_upload.pleaseWaitMessage")}!</span>
              <span>{t("documents_upload.dontClosePageMessage")}!</span>
            </div>
            <div className={style.loader}></div>
          </div>
        ) : (
          <div className="w-full">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <span>{t("documents_upload.checkResult")}</span>
            </div>
            <div>
              {
                (resultsTableData?.values && resultsTableData?.values?.length > 0)
                  ? <span className="font-bold text-gray-700 text-[14px]">Всего:{resultsTableData?.values?.length}</span>
                  :
                  null}
            </div>
            <div className="rounded-lg  overflow-x-auto bg-white">
              <table className="max-w-full w-full leading-normal">
                <thead className="border-b-2 border-gray-300">
                  <tr>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      №{t("documents_upload.serialNumber")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_upload.orderNumber")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_upload.codesAmount")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_upload.checkStatus")}
                    </th>
                  </tr>
                </thead>
                {resultsTableData?.values?.length === 0 ||
                  !resultsTableData?.values ? (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center py-5">
                        <div>
                          <EmptyListPlaceholderContent
                            title={t("documents_upload.noData")}
                            description={''}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="font-normal text-center">
                    {resultsTableData?.values
                      .slice(
                        resultsPagination?.pageIndex *
                        resultsPagination?.pageSize,
                        (resultsPagination?.pageIndex + 1) *
                        resultsPagination?.pageSize,
                      )
                      .map((value: any, i: any) => {
                        const index =
                          resultsPagination?.pageIndex *
                          resultsPagination?.pageSize +
                          i;
                        return (
                          <tr key={`tr-${index}-${value?.id}`}>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {index + 1 || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {value?.number || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {value?.marksWithErrorsAmount || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <StatusBadge type="error">
                                {value?.checkStatus || "-"}
                              </StatusBadge>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
              </table>
            </div>
            <Pagination
              totalItems={resultsTableData?.totalItems || 0}
              totalPages={
                resultsTableData?.totalItems
                  ? Math.ceil(
                    resultsTableData?.totalItems /
                    resultsPagination?.pageSize,
                  )
                  : 0
              }
              pagination={resultsPagination}
              setPagination={setResultsPagination}
            />
          </div>
        )}
      </>
    );
  };


  const renderFilterInputs = () => {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={() => {
            setPagination((prevValues: PaginationState) => {
              return { ...prevValues, pageIndex: 0 };
            });
            // setIsFilterTooltipOpen(false);
          }}
          // enableReinitialize={true}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {(formik) => (
            <Form className="max-w-[1585px] w-full m-t-[20px]">
              <div className="flex flex-row align-center justify-between">
                <p className="my-3 text-lg font-semibold">
                  {t("documents_upload.filters")}
                </p>
                <button
                  type="reset"
                  onClick={() => {
                    setIsFilterTooltipOpen(false);
                  }}
                >
                  <img src="/icons/close.svg" />
                </button>
              </div>
              <div className={style.inputField}>
                <div className={style.labelAndButtonWrapper}>
                  <label className={style.inputLabel}>
                    {t("documents_upload.orderNumber")}
                  </label>
                </div>
                <Field
                  type="text"
                  name="orderNumber"
                  placeholder={t("documentLabels.choose_option")}
                  className={style.placeholder}
                  value={formik?.values?.orderNumber}
                  onChange={(e: any) => {
                    formik.setFieldValue("orderNumber", e.target.value);
                    setInitialValues((prevValues: any) => {
                      return { ...prevValues, orderNumber: e.target.value };
                    });
                  }}
                />
              </div>
              <div className={style.inputField}>
                <div className={style.labelAndButtonWrapper}>
                  <label className={style.inputLabel}>
                    {t("documents_upload.marksAmount")}
                  </label>
                </div>
                <Field
                  type="number"
                  name="marksAmount"
                  placeholder={t("documentLabels.choose_option")}
                  min={0}
                  className={style.placeholder}
                  value={formik?.values?.marksAmount || ""}
                  onChange={(e: any) => {
                    formik.setFieldValue("marksAmount", e.target.value);
                    setInitialValues((prevValues: any) => {
                      return { ...prevValues, marksAmount: e.target.value };
                    });
                  }}
                />
              </div>

              <Calendar
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />

              <div className="flex flex-row gap-[10px]">
                <button
                  type="submit"
                  className=" bg-green text-white font-semibold py-2 px-4 rounded"
                >
                  <div className="flex flex-row items-center justify-between gap-[4px]">
                    <p>{t("mainButtons3.apply")}</p>
                    {isTableDataLoading && (
                      <div className={`!w-6 !p-1 ${style.loader}`}></div>
                    )}
                  </div>
                </button>
                <button
                  type="reset"
                  className=" bg-bg text-gray-500 font-semibold py-2 px-4 rounded"
                  onClick={() => {
                    setStartDate(undefined);
                    setEndDate(undefined);
                    setInitialValues({
                      orderNumber: "",
                      marksAmount: null,
                    });
                    formik.resetForm({
                      values: {
                        orderNumber: "",
                        marksAmount: null,
                      },
                    });
                    setPagination((prevValues: PaginationState) => {
                      return { ...prevValues, pageIndex: 0 };
                    });
                  }}
                >
                  <p>{t("mainButtons3.reset")}</p>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const toggleSort = () => {
    const newSortDirection = sort === "ASC" ? "DESC" : "ASC";
    setSort(newSortDirection);
  };

  const selectAllCheckboxes = (e: any) => {
    const checkboxOptions = tableData?.values?.map((item) => item?.id) || [];
    if (e?.target?.checked === true) {
      for (let i = 0; i < checkboxOptions?.length; i++) {
        selectedData?.indexOf(checkboxOptions[i]) === -1 &&
          setSelectedData((oldValues) => [...oldValues, checkboxOptions[i]]);
      }
    } else {
      for (let i = 0; i < checkboxOptions?.length; i++) {
        setSelectedData((oldValues) =>
          oldValues.filter((item) => item !== checkboxOptions[i]),
        );
      }
    }
  };

  const isAllChecked = () => {
    const checkboxOptions = tableData?.values?.map((item) => item?.id) || [];
    const isAllChecked = checkboxOptions.every((item) =>
      selectedData.includes(item),
    );
    return isAllChecked;
  };

  const handleSelection = (e: any, id: number) => {
    if (selectedData?.includes(id)) {
      setSelectedData((oldValues) => {
        return oldValues.filter((item) => item !== id);
      });
    } else {
      setSelectedData((oldValues) => [...oldValues, id]);
    }
  };

  const renderTable = () => {
    return (
      <div>
        <div
          className="w-full"
          onClick={() => isFilterTooltipOpen && setIsFilterTooltipOpen(false)}
        >
          {isResultsLoading && (
            <Loader />
          )}
          <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 align-center justify-between">
            <p>{t("documents_upload.orderNumbers")}</p>
            <div className="bg-white p-5 relative flex flex-row align-center justify-between">
              <div className="flex flex-row gap-[10px]">
                <button
                  type="button"
                  onClick={() => setIsFilterTooltipOpen(true)}
                  className=" bg-green text-white font-semibold py-2 px-4 rounded"
                  data-tooltip-offset={4}
                  data-tooltip-id="add-by-order-filter-type"
                >
                  {t("mainButtons3.filter")}
                </button>
                <button
                  onClick={() => {
                    setStartDate(undefined);
                    setEndDate(undefined);
                    setInitialValues({
                      orderNumber: "",
                      marksAmount: null,
                    });
                    setPagination((prevValues: PaginationState) => {
                      return { ...prevValues, pageIndex: 0 };
                    });
                  }}
                >
                  <img src="/icons/update.svg"></img>
                </button>
              </div>
            </div>
          </div>
          <div className="rounded-lg  overflow-x-auto bg-white">
            <table className="max-w-full w-full leading-normal">
              <thead className="border-b-2 border-gray-300">
                <tr>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200 flex flex-col items-center justify-between"
                    scope="col"
                  >
                    {t("documents_upload.select")}
                    <CheckBox
                      id={"checkbox-all"}
                      onChange={(e: any) => selectAllCheckboxes(e)}
                      checked={isAllChecked()}
                    />
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    №{t("documents_upload.serialNumber")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("documents_upload.orderNumber")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("documents_upload.orderStatus")}
                  </th>
                  <th
                    className="flex flex-row items-center justify-center px-5 py-3  text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("documents_upload.orderDate")}
                    <div className="flex flex-col">
                      <button className="h-6 w-6" onClick={toggleSort}>
                        <img
                          src={`/icons/arrow${sort === "ASC" ? "Down2" : "-top"}.svg`}
                          className={`block h-6 w-6  ${sort === "ASC" ? "text-red-500" : ""}`}
                          alt={sort === "ASC" ? "Ascending" : "Descending"}
                        />
                      </button>
                    </div>
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("documents_upload.note")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("documents_upload.marksAmount")}
                  </th>
                </tr>
              </thead>
              {tableData?.values?.length === 0 || !tableData?.values ? (
                <tbody>
                  <tr>
                    <td colSpan={8} className="text-center py-5">
                      <div>
                        <EmptyListPlaceholderContent
                          title={t("documents_upload.noData")}
                          description={''}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="font-normal text-center">
                  {isBasicSearch ? (
                    <>
                      {tableData?.values
                        ?.slice(
                          pagination?.pageIndex * pagination?.pageSize,
                          (pagination?.pageIndex + 1) * pagination?.pageSize,
                        )
                        .map((value: any, i: any) => {
                          const index =
                            pagination?.pageIndex * pagination?.pageSize + i;
                          return (
                            <tr key={`tr-${index}-${value?.id}`}>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                <CheckBox
                                  id={`checkbox-${value?.id}`}
                                  onChange={(e: PointerEvent) =>
                                    handleSelection(e, value?.id)
                                  }
                                  checked={selectedData?.includes(value?.id)}
                                />
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                {index + 1 || "-"}
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                {value?.number || "-"}
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                <StatusBadge
                                  type={
                                    value?.status === "PROCESSED_PAID"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {value?.status
                                    ? `${value?.status === "PROCESSED_PAID" ? t("documents_upload.processedPaidStatus") : t("documents_upload.unknownStatus")}`
                                    : ""}
                                </StatusBadge>
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                {value?.createdAt ? (
                                  value.createdAt.split(':').slice(0, 2).join(':')
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                {value?.note || "-"}
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                {value?.marksAmount || "-"}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  ) : (
                    tableData?.values?.map((value: any, index: any) => (
                      <tr key={`tr-${index}-${value?.id}`}>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          <CheckBox
                            id={`checkbox-${value?.id}`}
                            onChange={(e: PointerEvent) =>
                              handleSelection(e, value?.id)
                            }
                            checked={selectedData?.includes(value?.id)}
                          />
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {index + 1 || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.number || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          <StatusBadge
                            type={
                              value?.status === "PROCESSED_PAID" ? "active" : ""
                            }
                          >
                            {value?.status
                              ? `${value?.status === "PROCESSED_PAID" ? t("documents_upload.processedPaidStatus") : t("documents_upload.unknownStatus")}`
                              : ""}
                          </StatusBadge>
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.createdAt ? (
                            value.createdAt.split(':').slice(0, 2).join(':')
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.note || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.marksAmount || "-"}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              )}
            </table>
          </div>
          <div className="mx-7 my-2 text-left text-gray-600 font-semibold">
            <span>
              {t("documents_upload.selectedCount", {
                selectedCount: selectedData?.length,
                totalItems: tableData?.totalItems || 0,
              })}
            </span>
          </div>
          {isBasicSearch ? (
            <Pagination
              totalItems={tableData?.totalItems || 0}
              totalPages={
                tableData?.totalItems
                  ? Math.ceil(tableData?.totalItems / pagination?.pageSize)
                  : 0
              }
              pagination={pagination}
              setPagination={setPagination}
            />
          ) : (
            <Pagination
              totalItems={tableData?.totalItems || 0}
              totalPages={tableData?.totalPages || 0}
              pagination={pagination}
              setPagination={setPagination}
            />
          )}
        </div>
        <div className="z-[500] relative">
          <Tooltip
            isOpen={isFilterTooltipOpen}
            id="add-by-order-filter-type"
            className={classNames(style.wrapperTooltip, "drop-shadow-2xl")}
            openOnClick={true}
            clickable={true}
            noArrow={true}
            opacity={1}
            place={"left-start"}
          >
            {renderFilterInputs()}
          </Tooltip>
        </div>
      </div>
    );
  };

  const generateExcelValues = (data: any) => {
    type TransformedObject = {
      [key: string]: any;
    };

    const results =
      data &&
      data?.map((item: any, index: number) => {
        const newObj: TransformedObject = {};

        newObj[`№ ${t("documents_upload.serialNumber")}`] = index + 1;

        for (const [key, value] of Object.entries(item)) {
          if (key === "number") {
            const newKey = t("documents_upload.orderNumber");
            newObj[newKey] = value;
          }

          if (key === "marksWithErrorsAmount") {
            const newKey = t("documents_upload.codesAmount");
            newObj[newKey] = value;
          }

          if (key === "checkStatus") {
            const newKey = t("documents_upload.checkStatus");
            newObj[newKey] = value;
          }
        }

        return newObj;
      });

    return generateAndDownloadExcel(
      results,
      // `orders-check-${props.documentId}`,
      `${t('CheckResult.FileName')}`,
    );
  };

  return (
    <>
      <ModalComponent
        isOpen={errorModal}
        onClose={() => setErrorModal(null)}
        headerContent={
          <h1 className="h__modal">
            {t("documentNotification.notification")} -{" "}
            {t("documentNotification.check_fields")}:
          </h1>
        }
        content={
          <div className="error-list">
            <ul>
              {errorModal && errorModal?.length > 0 ? (
                errorModal.map((error: any, index: any) => (
                  <li className="border-b border-gray-300 py-1" key={index}>
                    {t(`error_dictionary.${error}`)}
                  </li>
                ))
              ) : (
                <li>{t("documentNotification.no_errors")}</li>
              )}
            </ul>
          </div>
        }
        customContent={
          <ModalButton
            type="button"
            onClick={() => {
              setErrorModal(null);
            }}
          >
            {t("mainButtons.accept")}
          </ModalButton>
        }
      />
      <ModalComponent
        isOpen={errorModal?.singleError}
        onClose={() => setErrorModal(null)}
        headerContent={
          <h1 className="h__modal">
            {" "}
            {t("documentNotification.notification")}
          </h1>
        }
        content={
          <div className="error-list">
            <p className="border-b border-gray-300 py-1">
              {t("documentStatus.error")}: {errorModal?.errorMessage}
            </p>
          </div>
        }
        customContent={
          <ModalButton
            type="button"
            onClick={() => {
              setErrorModal(null);
            }}
          >
            {t("mainButtons.accept")}
          </ModalButton>
        }
      />
      <ModalComponent
        classNameModal={style.modalWrapper}
        classNameContent="max-w-[1585px] w-full m-t-[20px]"
        content={renderTable()}
        isOpen={props.isOpen}
        onClose={onModalClose}
        footerContent={
          <button
            className={`text-white font-bold py-2 px-4 rounded ${selectedData?.length === 0 || resultsTableData?.values?.length === 0 ? `bg-bg` : `bg-green hover:brightness-75`}`}
            type="button"
            onClick={() => {
              onLoadResults();
              // onModalClose();
            }}
            disabled={
              selectedData?.length === 0 ||
              resultsTableData?.values?.length === 0
            }
          >
            {t("mainButtons3.add")}
          </button>
        }
        cancelText={t("mainButtons.close")}
      />
      <ModalComponent
        classNameModal={style.modalWrapper2}
        classNameContent="max-w-[1585px] w-full m-t-[20px]"
        content={renderResultsTable()}
        isOpen={props.isOpen && resultsModalOpen}
        footerContent={
          isResultsLoading ? (
            <></>
          ) : (
            <button
              className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => generateExcelValues(resultsTableData?.values)}
            >
              {t("documents_upload.donwloadResult")}
            </button>
          )
        }
        cancelText={t("mainButtons.close")}
        onClose={() => {
          onResultsModalClose();
        }}
      />
      <AlertBox
        isOpen={alertOpen}
        setIsOpen={setAlertOpen}
      />
    </>
  );
};

export default AddByOrderNumber;
