import React, { useEffect, useState } from "react";
import { ChangingLanguage } from "../../shared/components/ChangingLanguage";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form, FieldArray } from "formik";
import SelectCustomFormik from "../../shared/Ui/SelectCustom/SelectCustomFormik";
import style from "./autorization.module.css";
import CheckBox from "../../shared/Ui/CheckBox/CheckBox";
import * as Yup from "yup";
// @ts-ignore
import { NCALayerClient } from "ncalayer-js-client";
import ModalComponent from "../../shared/Ui/ModalComponent/ModalWindow";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { generateXmlFromData } from "../../common/libs/xml/createXml";
import { authenticate, DocumentHeadReg, viewAndPrintDocumentHead } from "../../common/api/authApi";
import Cookies from "js-cookie";
import { login, updateOrganizationData } from "../../shared/slice/authSlice";
import {
  checkUser,
  createHead,
  createUser,
  fetchBankCatalogList,
} from "../../common/api/registrationApi";
import { useAppDispatch } from "../../shared/hook";
import { normalizedLocale } from "../../common/libs/normalizedLocale";
import { bicData } from "../../common/libs/mock/bicData";
import ModalButton from "../../shared/Ui/Buttons/ModalButtons";
import AlertBox from "../../shared/Ui/AlertBox/AlertBox";
import { GetReferenceService } from "../../common/api/ordersApi";
import Loader from "../../shared/Ui/Loader/Loader";
import { FaExclamationCircle } from "react-icons/fa";
import { createXmlString } from "../../common/libs/xml/createHeadXml";
import DatePickerField from "../../shared/Ui/DataPicker/DatePickerField";



const Registration = () => {
  // @ts-ignore
  const InputMaskWithAny: React.FC<any> = InputMask;
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);
  const [isLoading, setIsLoading] = useState(false);
  const [isUOT, setIsUOT] = useState<any>(false);
  const [ecpKey, setEcpKey] = useState<any>();
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedIsVat, setIsCheckedIsVat] = useState(false);
  const [isCheckedHead, setIsCheckedHead] = useState(false);
  const [isCheckedNds, setIsCheckedNds] = useState(false);
  const [authStartInfo, setAuthStartInfo] = useState<any>();
  const [dataBic, setDataBic] = useState<any>(bicData);
  const [dataOgd, setDataOgd] = useState<any>(bicData);
  const [refBankCatalog, setRefBankCatalog] = useState<any[]>([]);
  const [dataGroup, setGroup] = useState<any>([{
    value: "1",
    label: "Ювелирные изделия",
  }]);
  const [dataNameBank, setDataNameBank] = useState<any>(bicData);
  const [errorModal, setErrorModal] = useState<any>(null);
  const [finalData, setfinalData] = useState<any>({
    signedXML: '',
    cms: '',
  });
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [isOpenModal, setIsOpenModal] = useState<any>(null)
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({
    IIN_BIN: "", // ИНН/БИН организации
    Organization_Name: "", // Наименование организации
    User_IIN: "", // ИИН руководителя
    User_fullName: "",
    User_Position: "", // Должность руководителя
    positionTranslate: {},
    organizationPhone: "",
    organizationEmail: "",

    City: "",
    Street: "", // Улица
    House: "", // Дом
    Building: "", // Строение (если есть) - необязательно
    Premises: "", // Помещение (если есть) - необязательно
    refOgd: "",

    Checking_Account: "", // Расчетный счет
    Bank_Identification_Code: "", // Банковский идентификационный код
    Email: "", // E-mail
    Phone: "", // Телефон
    Organization_Website: "", // Веб-сайт организации
    Select_Role: [], // Выбрать роль

    Phisical_Iin: "",
    Phisical_name: "",
    Phisical_surname: "",
    Phisical_otcestvo: "",
    Phisical_position: "",
    refBankCatalog: "",
    kbe: "",
    refProductGroup: "1",

    //новые поля
    organizationNameRu: '',
    organizationNameKz: '',
    organizationNameTypeId: '',
    organizationSizeId: '',
    residentCountryId: '',
    isVat: '',
    vatNumber: '',
    vatIssueDate: '',
    customerNameRu: '',
    customerNameKz: '',
    customerPositionRu: '',
    customerPositionKz: '',
    customerBasisTypeId: '',
    signBasisNumber: '',
    signBasisDate: '',
    docTypeId: '',
    docNumber: '',
    docIssuedBy: '',
    docDateFrom: '',
    docDateTo: '',
    factAddressRu: '',
    factAddressKz: '',
    postAddress: '',
    addressValue: '',
    Index: '',
    isHomeOwner: '',
    okedId: '',
  });

  const positionMapRu = {
    HEAD: "Первый Руководитель",
    EMPLOYEE_CAN_SIGN: "Лицо наделенное правом подписи",
    EMPLOYEE_CAN_SIGN_FINANCIAL: "Лицо наделенное правом подписи финансовых документов",
    EMPLOYEE_HR: "Сотрудник отдела кадров",
    EMPLOYEE_SIMPLE: "Сотрудник организации",
  };

  const positionMapKk = {
    HEAD: "Бірінші Басшысы",
    EMPLOYEE_CAN_SIGN: "Қол қою құқығы бар тұлға",
    EMPLOYEE_CAN_SIGN_FINANCIAL: "Қаржылық құжаттарға қол қою құқығы бар тұлға",
    EMPLOYEE_HR: "Кадрлар бөлімінің қызметкері",
    EMPLOYEE_SIMPLE: "Ұйым қызметкері",
  };

  const handleChange = (e: any) => {
    setIsChecked(e.target.checked);

  };

  const handleChangeHead = (e: any) => {
    setIsCheckedHead(e.target.checked);
  };


  useEffect(() => {
    GetReferenceService("/register-org/ogd/list")
      .then((response: any) => {
        const optionsOgd = response?.map((e: any) => ({
          value: e.id,
          label: `${e.name}:${e.code}`,
        }));

        setDataOgd(optionsOgd);
      })
      .catch((error) => {
        console.error("Error fetching bank catalog list:", error);
      });

    fetchBankCatalogList()
      .then((response: any) => {
        const optionsBic = response?.map((bank: any) => ({
          value: bank.id,
          label: bank.bik,
        }));

        const optionsName = response?.map((bank: any) => ({
          value: bank.id,
          label: locale === "ru" ? bank.nameRu : bank.nameKk,
        }));

        setDataBic(optionsBic);
        setDataNameBank(optionsName);
      })
      .catch((error) => {
        console.error("Error fetching bank catalog list:", error);
      });

    if (authStartInfo?.status && authStartInfo.status === "SUCCESS") {
      const { subjectInfo, certificateInfo } = authStartInfo.data.signInfo;
      const iinOrBin =
        subjectInfo.subjectType === "JURIDICAL"
          ? subjectInfo.bin
          : subjectInfo.iin;
      let position = t("authorization.firstManager");
      let positionTranslate = {
        ru: "Первый Руководитель",
        kk: "Бірінші Басшысы"
      }

      let selectRole: number[] = []; // Значение по умолчанию

      // Определение должности на основе ключевых типов пользователей
      if (certificateInfo.keyUserTypes.includes("HEAD") || certificateInfo.keyUserTypes.includes("INDIVIDUAL")) {
        position = t("authorization.firstManager");
      } else {
        const selectedPositions = certificateInfo.keyUserTypes
          ?.filter((key: any) => positionMapRu[key as keyof typeof positionMapRu] && positionMapKk[key as keyof typeof positionMapKk])
          ?.map((key: any) => locale === "ru" ? positionMapRu[key as keyof typeof positionMapRu] : positionMapKk[key as keyof typeof positionMapKk]);

        position = selectedPositions?.join(", ") || t("authorization.firstManager");

        positionTranslate = {
          ru: certificateInfo.keyUserTypes
            ?.map((key: any) => positionMapRu[key as keyof typeof positionMapRu])
            ?.join(", "),
          kk: certificateInfo.keyUserTypes
            ?.map((key: any) => positionMapKk[key as keyof typeof positionMapKk])
            ?.join(", "),
        };
      }

      if (authStartInfo.data.roles?.length < 2) {
        selectRole = [authStartInfo.data.roles[0].roleId];
      } else {
        selectRole = [];
      }

      if (
        !certificateInfo.keyUserTypes.includes("HEAD") &&
        authStartInfo.data.organization
      ) {
        setIsUOT(true);
        position = t("authorization.employee");
        positionTranslate = {
          ru: "Сотрудник",
          kk: "Қызметкер"
        }
      }
      if (isUOT) {
        console.log('isUOT-true');
        setInitialValues((prev: any) => ({
          ...prev,
          IIN_BIN: iinOrBin,
          Organization_Name:
            subjectInfo.organization ||
            `${subjectInfo.lastName} ${subjectInfo.firstName} ${subjectInfo.fatherName} `,
          User_IIN: authStartInfo.data.organization.iin,
          organizationNameRu: subjectInfo.organization ||
            `${subjectInfo.lastName} ${subjectInfo.firstName} ${subjectInfo.fatherName} `,
          organizationNameKz: subjectInfo.organization ||
            `${subjectInfo.lastName} ${subjectInfo.firstName} ${subjectInfo.fatherName} `,
          User_fullName: `${authStartInfo.data.organization.userSurname} ${authStartInfo.data.organization.userName} ${authStartInfo.data.organization.userPatronymic}`,
          // User_Position: position,
          // User_Position:{
          //   positionRu: authStartInfo.data.organization.positionRu,
          //   positionKz:authStartInfo.data.organization.positionKz
          //  },
          User_Position: authStartInfo.data.organization.positionRu,
          positionTranslate: positionTranslate,
          Select_Role: selectRole,
          Phisical_name: subjectInfo.firstName,
          Phisical_surname: subjectInfo.lastName,
          Phisical_otchestvo: subjectInfo.fatherName,
          Phisical_Iin: subjectInfo.iin
        }));
      } else if (!isUOT) {
        console.log('isUOT-false')
        setInitialValues((prev: any) => ({
          ...prev,
          IIN_BIN: iinOrBin,
          Organization_Name:
            subjectInfo.organization ||
            `${subjectInfo.lastName} ${subjectInfo.firstName} ${subjectInfo.fatherName} `,
          organizationNameRu: subjectInfo.organization ||
            `${subjectInfo.lastName} ${subjectInfo.firstName} ${subjectInfo.fatherName} `,
          organizationNameKz: subjectInfo.organization ||
            `${subjectInfo.lastName} ${subjectInfo.firstName} ${subjectInfo.fatherName} `,
          User_IIN: subjectInfo.iin,
          User_fullName: `${subjectInfo.lastName} ${subjectInfo.firstName} ${subjectInfo.fatherName} `,
          User_Position: position,
          positionTranslate: positionTranslate,
          Select_Role: selectRole,
        }));
      }


    }
  }, [authStartInfo, isUOT]);


  // Валидация для случая, когда isUOT = true
  const uotValidationSchema = Yup.object().shape({
    Phisical_Iin: Yup.string().required(t("documentsError.required")),
    Phisical_name: Yup.string().required(t("documentsError.required")),
    Phisical_surname: Yup.string().required(
      t("documentsError.required"),
    ),
    Phisical_otcestvo: Yup.string().nullable(),
    Email: Yup.string().required(t("documentsError.required")),
    Phone: Yup.string().required(t("documentsError.required")),
    // Select_Role:Yup.array().min(1,t("documentsError.required")).required(t("documentsError.required")),
  });

  // Валидация для случая, когда isUOT = false
  const defaultValidationSchema = Yup.object().shape({
    IIN_BIN: Yup.string()
      .required(t("documentsError.required"))
      ?.length(12, t("RegistrationPageAsr.iinValidate")),
    Organization_Name: Yup.string().required(t("documentsError.required")),
    refProductGroup: Yup.string().required(t("documentsError.required")),
    kbe: Yup.string()
      .required(t("documentsError.required"))
      ?.length(2, t("RegistrationPageAsr.kbeValidate")),
    refBankCatalog: Yup.string().required(t("documentsError.required")),
    refOgd: Yup.string().required(t("documentsError.required")),
    User_IIN: Yup.string()
      .required(t("documentsError.required"))
      ?.length(12, t("RegistrationPageAsr.iinValidate")),
    User_fullName: Yup.string().required(t("documentsError.required")),
    User_Position: Yup.string().required(t("documentsError.required")),
    organizationPhone: Yup.string().required(t("documentsError.required")),
    organizationEmail: Yup.string()
      .email(t("RegistrationPageAsr.EmailValidate"))
      .required(t("documentsError.required")),
    City: Yup.string().required(t("documentsError.required")),
    Street: Yup.string().required(t("documentsError.required")),
    House: Yup.string().required(t("documentsError.required")),
    Building: Yup.string(),
    Premises: Yup.string(),
    Checking_Account: Yup.string()
      .required(t("documentsError.required"))
      .length(20, t("RegistrationList.Checking_Account_length")),
    Bank_Identification_Code: Yup.string().required(
      t("documentsError.required"),
    ),
    Email: Yup.string()
      .email(t("RegistrationPageAsr.EmailValidate"))
      .required(t("documentsError.required")),
    Phone: Yup.string().required(t("documentsError.required")),
    Select_Role: Yup.array().min(1, t("documentsError.required")).required(t("documentsError.required")),

    // Новые поля:
    organizationNameRu: Yup.string().required(t("documentsError.required")),
    organizationNameKz: Yup.string().required(t("documentsError.required")),
    organizationNameTypeId: Yup.string().required(t("documentsError.required")),
    organizationSizeId: Yup.string().required(t("documentsError.required")),
    residentCountryId: Yup.string().required(t("documentsError.required")),
    // isVat: Yup.string().required(t("documentsError.required")),
    // vatNumber: Yup.string().required(t("documentsError.required")),
    // vatIssueDate: Yup.string().required(t("documentsError.required")),
    customerNameRu: Yup.string().required(t("documentsError.required")),
    customerNameKz: Yup.string().required(t("documentsError.required")),
    customerPositionRu: Yup.string().required(t("documentsError.required")),
    customerPositionKz: Yup.string().required(t("documentsError.required")),
    customerBasisTypeId: Yup.string().required(t("documentsError.required")),
    signBasisNumber: Yup.string().required(t("documentsError.required")),
    signBasisDate: Yup.string().required(t("documentsError.required")),
    docTypeId: Yup.string().required(t("documentsError.required")),
    docNumber: Yup.string().required(t("documentsError.required")),
    docIssuedBy: Yup.string().required(t("documentsError.required")),
    docDateFrom: Yup.string().required(t("documentsError.required")),
    docDateTo: Yup.string().required(t("documentsError.required")),
    factAddressRu: Yup.string().required(t("documentsError.required")),
    factAddressKz: Yup.string().required(t("documentsError.required")),
    postAddress: Yup.string().required(t("documentsError.required")),
    // addressValue: Yup.string().required(t("documentsError.required")),
    Index: Yup.string().required(t("documentsError.required")),
    isHomeOwner: Yup.string().required(t("documentsError.required")),
    okedId: Yup.string().required(t("documentsError.required")),
  });


  async function checkFIrstAuth() {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
      setAlertOpen({
        status: "error",
        message: "Не удалось подключиться к NCALayer",
      });
      return;
    }

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const xmlData = `<time>${currentTimeInSeconds}</time>`;
    let xmlSignature;

    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xmlData,
        NCALayerClient.basicsXMLParams,
        NCALayerClient.basicsSignerTestAny,
        // basicsSignerSignAny
        "ru",
      );

    } catch (error) {
    }





    if (xmlSignature[0]) {
      setIsLoading(true)
      checkUser(xmlSignature[0])
        .then((response) => {

          setAuthStartInfo(response);
          setEcpKey(response);
          setIsLoading(false)
        })
        .catch((error) => {
          // Проверка на наличие response и message в объекте ошибки
          const errorMessage =
            error.response?.data?.message || "Произошла ошибка";
          setErrorModal({ errorMessage });
          setIsLoading(false)
        });
    }

  }


  // Подпись с помощью Rsa Руководителя первый шаг запрос дока
  async function registrationRsaHead(xml: any) {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
      setAlertOpen({
        status: "error",
        message: "Не удалось подключиться к NCALayer",
      });
      return;
    }
    console.log(1111, xml);

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    let xmlSignature: any;

    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xml,
        NCALayerClient.basicsXMLParams,
        process.env.REACT_APP_ETRACK_TEST === "true"
          ? NCALayerClient.basicsSignerTestAny
          : NCALayerClient.basicsSignerSignAny,
        "ru",
      );

    } catch (error) {
      console.log("Ошибка при выполнении XML-подписи:", error);
    }

    if (Array.isArray(xmlSignature) && xmlSignature[0]) {
      setIsLoading(true)
      setfinalData((prev: any) => ({ ...prev, signedXML: xmlSignature[0] }));
      DocumentHeadReg(xmlSignature[0])
        .then((response) => {
          setIsOpenModal(response.data)
          setIsLoading(false)
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || "Произошла ошибка";
          setErrorModal({ errorMessage });
          setIsLoading(false)
        })
    }
  }

  // Подпись Самая бешенная Cms последний второй шаг подпись дока и регистрация
  async function registrationRsaHeadEnd() {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
      setAlertOpen({
        status: "error",
        message: "Не удалось подключиться к NCALayer",
      });
      return;
    }

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    let cmsData = `${isOpenModal[0]?.sha256Hash},${isOpenModal[1]?.sha256Hash}`
    let xmlSignature;

    try {
      xmlSignature = await ncalayerClient.basicsSignCMS(
        NCALayerClient.basicsStoragesAll,
        cmsData,
        NCALayerClient.basicsCMSParams,
        process.env.REACT_APP_ETRACK_TEST === "true" ? NCALayerClient.basicsSignerTestAny : NCALayerClient.basicsSignerAny,
        // basicsSignerSignAny
        "ru",
      );
      console.log("Подпись cms 1:", xmlSignature);
    } catch (error) {
      console.log("Ошибка при выполнении XML-подписи:", error);
      return;
    }

    if (xmlSignature) {
      if (Array.isArray(xmlSignature)) {
        // Если это массив, берем первый элемент
        xmlSignature = xmlSignature[0];
      }

      // Убираем лишние строки и пробелы
      xmlSignature = xmlSignature
        .replace('-----BEGIN CMS-----', '')
        .replace('-----END CMS-----', '')
        .trim();

      console.log("Подпись cms 2 final:", xmlSignature);

      createHead(finalData.signedXML, xmlSignature)
        .then((response) => {
          setAlertOpen({
            status: "SUCCESS",
            message: "Вы успешно зарегистрировались",
          });
          navigate("/authorization");
        })
        .catch((error) => {
          // Проверка на наличие response и message в объекте ошибки
          const errorMessage =
            error.response?.data?.message || "Произошла ошибка";
          setErrorModal({ errorMessage });
        });
    }
  }

  console.log(3333, process.env.REACT_APP_ASR_ENABLED);


  // Подпись с помощью Rsa Простого сотрудника
  async function registrationRsaUot(xml: any) {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
      setAlertOpen({
        status: "error",
        message: "Не удалось подключиться к NCALayer",
      });
      return;
    }

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    let xmlSignature;

    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xml,
        NCALayerClient.basicsXMLParams,
        process.env.REACT_APP_ETRACK_TEST === "true"
          ? NCALayerClient.basicsSignerTestAny
          : NCALayerClient.basicsSignerSignAny,
        "ru",
      );
      console.log("Подпись XML:", xmlSignature);
    } catch (error) {
      console.log("Ошибка при выполнении XML-подписи:", error);
    }

    createUser(xmlSignature[0])
      .then((response) => {
        setAlertOpen({
          status: "SUCCESS",
          message: "Вы успешно зарегистрировались",
        });
        navigate("/authorization");
      })
      .catch((error) => {
        // Проверка на наличие response и message в объекте ошибки
        const errorMessage =
          error.response?.data?.message || "Произошла ошибка";
        setErrorModal({ errorMessage });
      });
  }

  // Это ЗАПРОС НА ФАЙЫ ДОКА DOWLOAD!
  const handleDownloadClick = async (filename: any, name: any) => {
    try {
      setIsLoading(true);
      const fileBlob = await viewAndPrintDocumentHead(filename);
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;

      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl); // освободите ресурсы, связанные с URL
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Отправка запроса
  const handleSubmit = async (values: any) => {
    const HeadData = {
      iinBin: isUOT ? values.IIN_BIN : (values.Phisical_Iin || initialValues.IIN_BIN),
      userIIN: isUOT ? values.Phisical_Iin : values.Phisical_Iin || initialValues.User_IIN,
      userName: values.Phisical_name || values.User_fullName.split(" ")[1],
      userSurname: values.Phisical_surname || values.User_fullName.split(" ")[0],
      userPatronymic: values.Phisical_otcestvo || values.User_fullName.split(" ")[2],
      userPositionRu: initialValues.positionTranslate.ru,
      userPositionKz: values.positionTranslate.kk,
      organizationName: values.Phisical_surname || initialValues.Organization_Name,
      organizationWebsite: values.Organization_Website,
      organizationPhone: values.organizationPhone,
      email: values.Email,
      phone: values.Phone,
      organizationEmail: values.organizationEmail,
      bankIdentificationCode: dataBic.find((item: any) => item.value === values.refBankCatalog)?.label || values.Bank_Identification_Code,
      checkingAccount: values.Checking_Account,
      selectRole: values.Select_Role,
      address: {
        city: values.City,
        premises: values.Premises,
        street: values.Street,
        house: values.House,
        building: values.Building,
        refOgd: values.refOgd,
      },
      refBankCatalog: values.refBankCatalog,
      kbe: values.kbe,
      refProductGroup: values.refProductGroup,
      asrRegisterRequest: {
        organization: {
          bin: isUOT ? values.IIN_BIN : (values.Phisical_Iin || initialValues.IIN_BIN),
          nameRu: values.organizationNameRu,
          nameKz: values.organizationNameKz,
          registeredAddressRu: `${values.City} ${values.Street} ${values.House} ${values.Building} ${values.Premises}`,
          registeredAddressKz: `${values.City} ${values.Street} ${values.House} ${values.Building} ${values.Premises}`,
          factAddressRu: values.factAddressRu,
          factAddressKz: values.factAddressKz,
          residentCountryId: values.residentCountryId,
          organizationNameTypeId: values.organizationNameTypeId,
          organizationSizeId: values.organizationSizeId,
          okedId: values.okedId,
          externalId: "",// -
          ceo: values.User_fullName, // - 
          primaryCustomerDoc: {
            docNumber: values.docNumber,
            docIssuedBy: values.docIssuedBy,
            docDateFrom: values.docDateFrom,
            docDateTo: values.docDateTo,
            docTypeId: values.docTypeId,
          },
        },
        agreement: {
          providerId: "1", //! const
          customerId: "614343", // -
          customerRoleSpecId: "123",//! options
          enterpriseUnitId: "11",// options
          filialId: "17",// cosnt 
          specificationId: "1",// const 
          authTypeId: "1",// options
          sum: "5600",// - 
          num: "1-0345-F",// generate
          openDate: "2021-09-27",// const nowDay
          closeDate: "2021-09-27",//! 2099
          ownerAgreementAuthorization: { // необяз obj
            staffUnitId: "36985", //!
            internalEmployeeId: "5", //!
            nameRu: "Маликов Султан",//!
            nameKz: "Маликов Султан",//!
            attorneyLetterNumber: "IIRR-896325",//!
            attorneyLetterDate: "2024-11-19",//!
            bankId: "52",//!
            iik: values.Checking_Account,// 20 simbols
            enterpriseUnitAddress: `${values.City} ${values.Street} ${values.House} ${values.Building} ${values.Premises}`,//!
            email: values.organizationEmail,//!
            phoneNums: values.organizationPhone,//!
          },
          customerAgreementAuthorization: { // необяз obj
            nameRu: values.customerNameRu,
            nameKz: values.customerNameKz,
            positionRu: values.customerPositionRu,
            positionKz: values.customerPositionKz,
            financialAccountData: {
              bankId: "58",// options asr
              iik: values.Checking_Account,// 20 simbols
            },
            customerBasisTypeId: values.customerBasisTypeId,//options
            signBasisNumber: values.signBasisNumber,
            signBasisDate: values.signBasisDate,
            address: `${values.City} ${values.Street} ${values.House} ${values.Building} ${values.Premises}`,
            email: values.organizationEmail,
            phoneNums: values.organizationPhone,
            postAddress: values.postAddress,
            zipCode: values.Index,
            isVat: isCheckedIsVat ? 1 : 0,
            vatNumber: values.vatNumber,
            vatIssueDate: values.vatIssueDate,
          },
        },
        customerAccount: { // блок для лицевого счета 
          agreementId: "0", // id при рег 0
          customerAccountCategoryId: "0",//options
          billDeliveryTypeId: "1",// 1 or 2
          geoPlaceId: "258", // options
          filialId: "17", // options
          budgetId: "100002", // options 1 2 3
          digitalBillDeliveryData: [ // -
            {
              digitalBillDeliverySpecId: 2,//1 or 2
              addresses: [{ address: "7777777777" }],
            },
          ],// if 1 {}
          paymentFormId: "1",// 1 const
          externalId: "369258",// -
          dontOff: "1",// ?
          isHomeOwner: '1', // const 1 always true 
          endUser: "string", // -
          caManagerLdapUser: "Samat", // -
          ndsTypeId: "1", // options
        },
      },
    };

    const beckendData = {
      ...initialValues,
      IIN_BIN: isUOT ? values.IIN_BIN : (values.Phisical_Iin || initialValues.IIN_BIN),
      User_IIN: isUOT ? values.Phisical_Iin : values.Phisical_Iin || initialValues.User_IIN,
      // IIN_BIN: values.Phisical_Iin || initialValues.IIN_BIN,
      // User_IIN: values.Phisical_Iin || initialValues.User_IIN,
      Organization_Name:
        values.Phisical_surname || initialValues.Organization_Name,
      User_Surname:
        values.Phisical_surname || values.User_fullName.split(" ")[0],
      User_Name: values.Phisical_name || values.User_fullName.split(" ")[1],
      User_Patronymic:
        values.Phisical_otcestvo || values.User_fullName.split(" ")[2],
      city: values.City,
      street: values.Street, // Улица
      house: values.House, // Дом
      organizationPhone: values.organizationPhone,
      organizationEmail: values.organizationEmail,
      building: values.Building, // Строение (если есть) - необязательно
      premises: values.Premises, // Помещение (если есть) - необязательно
      refOgd: values.refOgd,
      Checking_Account: values.Checking_Account, // Расчетный счет
      Bank_Identification_Code: dataBic.find((item: any) => item.value === values.refBankCatalog)?.label || values.Bank_Identification_Code, // Банковский идентификационный код
      Email: values.Email, // E-mail
      Phone: values.Phone, // Телефон
      Organization_Website: values.Organization_Website, // Веб-сайт организации
      Select_Role: values.Select_Role,
      User_Position_ru: initialValues.positionTranslate.ru,
      User_Position_kz: values.positionTranslate.kk,
      refBankCatalog: values.refBankCatalog,
      kbe: values.kbe,
      refProductGroup: values.refProductGroup,
    };



    if (!isUOT) {
      const xmlData = createXmlString(HeadData);
      console.log('xmlData', xmlData);
      registrationRsaHead(xmlData)
    } else {
      const xmlData = generateXmlFromData(beckendData);
      console.log('xmlData', xmlData);
      registrationRsaUot(xmlData);
    }
  };

  // Страница с кнопкой Эцп
  if (!ecpKey) {
    return (
      <>
        {isLoading && (
          <Loader />
        )}
        {errorModal && (
          <ModalComponent
            isOpen={errorModal}
            onClose={() => {
              setErrorModal(null);
            }}
            headerContent={ <h1 className="h__modal">
              {t("documentNotification.notification")}
            </h1>}
            content={
              <p>
                {" "}
                {`${errorModal?.errorMessage ? errorModal?.errorMessage : "ошибка."}`}
              </p>
            }
            customContent={
              <ModalButton
                type="button"
                onClick={() => {
                  setErrorModal(null);
                }}
              >
                {t("mainButtons.accept")}
              </ModalButton>
            }
          />
        )}
        <div className="bg-bg flex items-center justify-center h-screen">
          <div className="flex flex-col justify-between h-[300px] bg-white p-8 rounded-lg shadow-lg w-96 mx-10">
            <a href="/" className="flex justify-center mb-4">
              <img
                src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
                alt="E-Track Logo"
                className="h-12"
              />
            </a>
            <div className="flex flex-col space-y-4">
              <button
                onClick={checkFIrstAuth}
                className="bg-green text-white font-semibold py-2 px-4 rounded"
              >
                {t("registrationWithECP.title")}
              </button>
            </div>
            <div className="flex flex-col space-y-4">
              <div className="flex items-center justify-between">
                <a className="text-gray-600 text-sm"></a>
                <div className="relative">
                  <div>
                    <ChangingLanguage onlyDesktop={true} />
                  </div>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
                    <i className="fas fa-chevron-down"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (inputValue?.length < 3 && dataNameBank?.length === 0) {
      return t("введите Нтин");
    } else {
      return t("documents_orders.no_data_title");
    }
  }

  function setIsFormChanged(arg0: boolean) {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      <ModalComponent
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(null);
          setIsCheckedHead(false)
        }}
        headerContent={
          <div>
            <div className={style.modalHeader}>
              <div className={style.modalTitle}>{t("registrationWithECP.contractJewelryGroup")}</div>
              <div className={style.modalDesc}>{t("registrationWithECP.connectionInstructions")}</div>
            </div>
            <div className={style.HorLine}></div>
          </div>

        }
        content={
          <div className="flex flex-col gap-y-[20px]">
            <div className="flex w-full gap-x-[5px] items-center  text-black">
              <img src="assets/paperclip.svg" className="w-[20px]" />
              <div className={style.modalDesc}>{t("registrationWithECP.beforeSigning")}</div>
            </div>
            <div className={`flex flex-col gap-y-[10px] pl-[20px] text-black ${style.modalDesc}`} >
              <a href="#"
                className="custom-link text-blue-500 hover:text-blue-700"
                onClick={() => {
                  if (isOpenModal) {
                    handleDownloadClick(isOpenModal[0]?.filename, t("registrationWithECP.standardContract"))
                    // handleDownloadClick(t("registrationWithECP.standardContract"))
                  }
                }}
              >
                {t("registrationWithECP.standardContract")}
              </a>
              <a href="#"
                className="custom-link text-blue-500 hover:text-blue-700"
                onClick={() => {
                  if (isOpenModal) {
                    handleDownloadClick(isOpenModal[1]?.filename, t("registrationWithECP.applicationAgreement"))
                    // handleDownloadClick(t("registrationWithECP.applicationAgreement"))
                  }
                }}
              >
                {t("registrationWithECP.applicationAgreement")}
              </a>
            </div>
            <div className="flex gap-x-[5px] w-full items-center">
              <CheckBox
                id="myCheckbox"
                checked={isCheckedHead}
                onChange={handleChangeHead}
              />
              <label className={`max-w-full text-black ${style.modalDesc}`}>
                {t("registrationWithECP.agreeWithTerms")}
              </label>
            </div>

          </div>
        }
        customContent={
          <div className="flex gap-[20px]">
            <ModalButton
              color='green'
              type="button"
              disabled={!isCheckedHead}
              onClick={() => {
                registrationRsaHeadEnd()
              }}
            >
              {t("registrationWithECP.signAndSend")}
            </ModalButton>
            <ModalButton
              color="grey"
              type="button"
              onClick={() => {
                setIsOpenModal(null);
                setIsCheckedHead(false)

              }}
            >
              {t("mainButtons.close")}
            </ModalButton>
          </div>

        }
      />
      <ModalComponent
        isOpen={errorModal}
        onClose={() => {
          setErrorModal(null);
        }}
        headerContent={<h1 className={style.modal__header}>{t("registrationWithECP.notification")}</h1>}
        content={
          <p className={style.modal__text}>
            {`${errorModal?.errorMessage ? errorModal?.errorMessage : "ошибка."}`}
          </p>
        }
        customContent={
          <ModalButton
            type="button"
            onClick={() => {
              setErrorModal(null);
            }}
          >
            {t("mainButtons.accept")}
          </ModalButton>
        }
      />
      <div className={`${style.mainWrapper} bg-bg h-screen`}>
        <Formik
          initialValues={initialValues}
          validationSchema={
            isUOT ? uotValidationSchema : defaultValidationSchema
          }
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formik) => (
            <Form>
              <div
                className={`${style.formWrapper} flex flex-col items-center justify-center overflow-y-auto gap-4`}
              >
                <a href="/" className="flex justify-center mb-4">
                  <img
                    src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
                    alt="E-Track Logo"
                    className="h-12"
                  />
                </a>
                <div className={style.registrationTitle}>
                  <h2
                    className={`${style.titleTableMargin} ${style.orgTitleMain} m-0`}
                  >
                    {t("registrationWithECP.registrationForm")}
                  </h2>

                  <div className={style.testButton}>
                    <ChangingLanguage onlyDesktop={true} />
                  </div>
                </div>
                {/* Блок об организации */}
                <div className={style.blockConfigRoot}>
                  <div className={style.titleWrapper}>
                    <p className="text-xs font-medium">
                      {t("registrationWithECP.organizationDetails")}
                    </p>
                  </div>
                  <div className={style.registrationConfigRoot}>


                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>
                          {t("registrationWithECP.orgINN")}
                        </label>
                        {formik.touched.IIN_BIN &&
                          formik.errors.IIN_BIN && (
                            <div className={style.tooltipWrapper}>
                              <FaExclamationCircle className={style.errorIcon} />
                              <div className={style.tooltipText}>
                                {typeof formik.errors.IIN_BIN ===
                                  "string"
                                  ? formik.errors.IIN_BIN
                                  : t(
                                    "notification_from_third_countries.error_customs_clearance_reg_date",
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="IIN_BIN"
                        placeholder={t('RegistrationList.organizationIIN')}
                        className={style.placeholder}
                        disabled={true}
                      />
                    </div>

                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>
                          {t("registrationWithECP.orgName1")}
                        </label>
                        {formik.touched.organizationNameRu &&
                          formik.errors.organizationNameRu && (
                            <div className={style.tooltipWrapper}>
                              <FaExclamationCircle className={style.errorIcon} />
                              <div className={style.tooltipText}>
                                {typeof formik.errors.organizationNameRu ===
                                  "string"
                                  ? formik.errors.organizationNameRu
                                  : t(
                                    "notification_from_third_countries.error_customs_clearance_reg_date",
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="organizationNameRu"
                        placeholder={t('RegistrationList.organizationName')}
                        className={style.placeholder}
                        disabled={true}
                      />
                    </div>

                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>
                          {t("registrationWithECP.orgName2")}
                        </label>
                        {formik.touched.organizationNameKz &&
                          formik.errors.organizationNameKz && (
                            <div className={style.tooltipWrapper}>
                              <FaExclamationCircle className={style.errorIcon} />
                              <div className={style.tooltipText}>
                                {typeof formik.errors.organizationNameKz ===
                                  "string"
                                  ? formik.errors.organizationNameKz
                                  : t(
                                    "notification_from_third_countries.error_customs_clearance_reg_date",
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="organizationNameKz"
                        placeholder={t('RegistrationList.organizationName')}
                        className={style.placeholder}
                        disabled={false}
                      />
                    </div>

                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>
                          {t("registrationWithECP.directorIIN")}
                        </label>
                        {formik.touched.User_IIN &&
                          formik.errors.User_IIN && (
                            <div className={style.tooltipWrapper}>
                              <FaExclamationCircle className={style.errorIcon} />
                              <div className={style.tooltipText}>
                                {typeof formik.errors.User_IIN ===
                                  "string"
                                  ? formik.errors.User_IIN
                                  : t(
                                    "notification_from_third_countries.error_customs_clearance_reg_date",
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="User_IIN"
                        placeholder={t('RegistrationList.directorIIN')}
                        className={style.placeholder}
                        disabled={true}
                      />
                    </div>

                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>{!isUOT ? t("registrationWithECP.fullName") : t("registrationWithECP.fullNameForUot")}</label>
                        {formik.touched.User_fullName &&
                          formik.errors.User_fullName && (
                            <div className={style.tooltipWrapper}>
                              <FaExclamationCircle className={style.errorIcon} />
                              <div className={style.tooltipText}>
                                {typeof formik.errors.User_fullName ===
                                  "string"
                                  ? formik.errors.User_fullName
                                  : t(
                                    "notification_from_third_countries.error_customs_clearance_reg_date",
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="User_fullName"
                        placeholder={t('RegistrationList.directorFullName')}
                        className={style.placeholder}
                        disabled={true}
                      />
                    </div>

                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>{!isUOT ? t("registrationWithECP.position") : t("registrationWithECP.positionForUot")}</label>
                        {formik.touched.User_Position &&
                          formik.errors.User_Position && (
                            <div className={style.tooltipWrapper}>
                              <FaExclamationCircle className={style.errorIcon} />
                              <div className={style.tooltipText}>
                                {typeof formik.errors.User_Position ===
                                  "string"
                                  ? formik.errors.User_Position
                                  : t(
                                    "notification_from_third_countries.error_customs_clearance_reg_date",
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="User_Position"
                        placeholder={t('RegistrationList.position')}
                        className={style.placeholder}
                        disabled={true}
                      />
                    </div>


                    {!isUOT && (
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("registrationWithECP.productGroup")}
                          </label>
                          {formik.touched.refProductGroup &&
                            formik.errors.refProductGroup && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.refProductGroup ===
                                    "string"
                                    ? formik.errors.refProductGroup
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="refProductGroup"
                          options={dataGroup}
                          placeholder={t('RegistrationList.productGroup')}
                          size="sm"
                          isMulti={false}
                          isDisabled={true}
                        />
                      </div>)}
                    {!isUOT && (
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("registrationWithECP.orgPhone")}
                          </label>
                          {formik.touched.organizationPhone &&
                            formik.errors.organizationPhone && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.organizationPhone ===
                                    "string"
                                    ? formik.errors.organizationPhone
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>

                        <InputMaskWithAny
                          mask="+7 (999) 999-99-99"
                          maskChar="_"
                          type="text"
                          name="organizationPhone"
                          placeholder={t('RegistrationList.organizationPhone')}
                          className={style.placeholder}
                          value={formik.values.organizationPhone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    )}
                    {!isUOT && (
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("registrationWithECP.orgEmail")}
                          </label>
                          {formik.touched.organizationEmail &&
                            formik.errors.organizationEmail && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.organizationEmail ===
                                    "string"
                                    ? formik.errors.organizationEmail
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="organizationEmail"
                          placeholder={t('RegistrationList.organizationEmail')}
                          className={style.placeholder}
                        />
                      </div>
                    )}
                    {!isUOT ? (
                      <>
                        <div className={style.inputField}>
                          <div className={style.labelAndButtonWrapper}>
                            <label className={style.inputLabel}>
                              {t("RegistrationPageAsr.organizationNameTypeId")}*
                            </label>
                            {formik.touched.organizationNameTypeId &&
                              formik.errors.organizationNameTypeId && (
                                <div className={style.tooltipWrapper}>
                                  <FaExclamationCircle className={style.errorIcon} />
                                  <div className={style.tooltipText}>
                                    {typeof formik.errors.organizationNameTypeId ===
                                      "string"
                                      ? formik.errors.organizationNameTypeId
                                      : t(
                                        "notification_from_third_countries.error_customs_clearance_reg_date",
                                      )}
                                  </div>
                                </div>
                              )}
                          </div>                
                          <Field
                            component={SelectCustomFormik}
                            name="organizationNameTypeId"
                            options={[
                              { value: "1", label: "Государственная организация" },
                              { value: "2", label: "Частная организация" },
                              { value: "3", label: "Некоммерческая организация" },
                              { value: "4", label: "Международная организация" }
                            ]}
                            placeholder={t('RegistrationList.ogdCodePlaceholder')}
                            size="sm"
                            isMulti={false}
                          />
                        </div>

                        <div className={style.inputField}>
                          <div className={style.labelAndButtonWrapper}>
                            <label className={style.inputLabel}>
                              {t("RegistrationPageAsr.organizationSizeId")}*
                            </label>
                            {formik.touched.organizationSizeId &&
                              formik.errors.organizationSizeId && (
                                <div className={style.tooltipWrapper}>
                                  <FaExclamationCircle className={style.errorIcon} />
                                  <div className={style.tooltipText}>
                                    {typeof formik.errors.organizationSizeId ===
                                      "string"
                                      ? formik.errors.organizationSizeId
                                      : t(
                                        "notification_from_third_countries.error_customs_clearance_reg_date",
                                      )}
                                  </div>
                                </div>
                              )}
                          </div>                   
                          <Field
                          value={formik?.values?.docTypeId || null}
                          component={SelectCustomFormik}
                          name="organizationSizeId"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          isClearable={true}
                          size="dm"
                          isMulti={false}
                          options={[
                            { value: "1", label: "Микропредприятие" },
                            { value: "2", label: "Малое предприятие" },
                            { value: "3", label: "Среднее предприятие" },
                            { value: "4", label: "Крупное предприятие" }
                          ]}
      
                        />
                        </div>

                        <div className={style.inputField}>
                          <div className={style.labelAndButtonWrapper}>
                            <label className={style.inputLabel}>
                              {t("RegistrationPageAsr.okedId")}*
                            </label>
                            {formik.touched.okedId &&
                              formik.errors.okedId && (
                                <div className={style.tooltipWrapper}>
                                  <FaExclamationCircle className={style.errorIcon} />
                                  <div className={style.tooltipText}>
                                    {typeof formik.errors.okedId ===
                                      "string"
                                      ? formik.errors.okedId
                                      : t(
                                        "notification_from_third_countries.error_customs_clearance_reg_date",
                                      )}
                                  </div>
                                </div>
                              )}
                          </div>
                                <Field
                            component={SelectCustomFormik}
                            name="okedId"
                            options={ [
                              { value: "01", label: "Сельское хозяйство, лесное хозяйство и рыболовство" },
                              { value: "02", label: "Добыча полезных ископаемых" },
                              { value: "03", label: "Обрабатывающая промышленность" },
                              { value: "04", label: "Производство и распределение электроэнергии, газа и воды" },
                              { value: "05", label: "Строительство" },
                              { value: "06", label: "Оптовая и розничная торговля" },
                              { value: "07", label: "Транспорт и логистика" },
                              { value: "08", label: "Информация и связь" },
                              { value: "09", label: "Финансовая и страховая деятельность" },
                              { value: "10", label: "Недвижимость" },
                              { value: "11", label: "Профессиональная, научная и техническая деятельность" },
                              { value: "12", label: "Административная и вспомогательная деятельность" },
                              { value: "13", label: "Государственное управление и оборона" },
                              { value: "14", label: "Образование" },
                              { value: "15", label: "Здравоохранение и социальные услуги" }
                            ]}
                            placeholder={t('RegistrationList.ogdCodePlaceholder')}
                            size="sm"
                            isMulti={false}
                          />
                        </div>

                        <div className={style.inputField}>
                          <div className={style.labelAndButtonWrapper}>
                            <label className={style.inputLabel}>
                              {t("RegistrationPageAsr.residentCountryId")}*
                            </label>
                            {formik.touched.residentCountryId &&
                              formik.errors.residentCountryId && (
                                <div className={style.tooltipWrapper}>
                                  <FaExclamationCircle className={style.errorIcon} />
                                  <div className={style.tooltipText}>
                                    {typeof formik.errors.residentCountryId ===
                                      "string"
                                      ? formik.errors.residentCountryId
                                      : t(
                                        "notification_from_third_countries.error_customs_clearance_reg_date",
                                      )}
                                  </div>
                                </div>
                              )}
                          </div>
                          <Field
                            component={SelectCustomFormik}
                            name="residentCountryId"
                            options={[
                              { value: "1", label: "Казахстан" },
                              { value: "2", label: "Россия" },
                              { value: "3", label: "США" },
                              { value: "4", label: "Украина" },
                              { value: "5", label: "Германия" },
                              { value: "6", label: "Франция" },
                              { value: "7", label: "Китай" },
                              { value: "8", label: "Япония" },
                              { value: "9", label: "Узбекистан" },
                              { value: "10", label: "Турция" },
                              { value: "11", label: "Индия" },
                              { value: "12", label: "Канада" },
                              { value: "13", label: "Италия" },
                              { value: "14", label: "Испания" },
                              { value: "15", label: "Бразилия" }
                            ]}
                            placeholder={t("RegistrationPageAsr.choose_option")}
                            size="sm"
                            isMulti={false}
                          />
                        </div>

                        <div className="flex items-center w-[100%] mt-2.5 mb-2.5 gap-2">
                          <CheckBox
                            id="isCheckedNds"
                            checked={isCheckedNds}
                            onChange={(e: any) => setIsCheckedNds((prev) => !prev)}
                          />
                          <label className="text-xs font-medium">
                            {t("RegistrationPageAsr.isVat")}
                          </label>

                          <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
                        </div>


                        {isCheckedNds ? (
                          <>
                            <div className={style.inputField}>
                              <div className={style.labelAndButtonWrapper}>
                                <label className={style.inputLabel}>
                                  {t("RegistrationPageAsr.vatNumber")}
                                </label>
                                {formik.touched.vatNumber &&
                                  formik.errors.vatNumber && (
                                    <div className={style.tooltipWrapper}>
                                      <FaExclamationCircle className={style.errorIcon} />
                                      <div className={style.tooltipText}>
                                        {typeof formik.errors.vatNumber ===
                                          "string"
                                          ? formik.errors.vatNumber
                                          : t(
                                            "notification_from_third_countries.error_customs_clearance_reg_date",
                                          )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <Field
                                type="text"
                                name="vatNumber"
                                placeholder={t("RegistrationPageAsr.choose_option")}
                                className={style.placeholder}
                              />
                            </div>

                            <div className={style.inputField}>
                              <div className={style.labelAndButtonWrapper}>
                                <label className={style.inputLabel}>
                                  {t("RegistrationPageAsr.vatIssueDate")}
                                </label>
                                {formik.touched.vatIssueDate &&
                                  formik.errors.vatIssueDate && (
                                    <div className={style.tooltipWrapper}>
                                      <FaExclamationCircle className={style.errorIcon} />
                                      <div className={style.tooltipText}>
                                        {typeof formik.errors.vatIssueDate ===
                                          "string"
                                          ? formik.errors.vatIssueDate
                                          : t(
                                            "notification_from_third_countries.error_customs_clearance_reg_date",
                                          )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <DatePickerField
                                name="vatIssueDate"
                                placeholder={t("RegistrationPageAsr.choose_date")}
                              />
                            </div>
                          </>
                        ) : null}
                      </>
                    ) : null}

                  </div>
                </div>

                {/* Блок об Лицо, уполномоченное подписывать документы */}
                {!isUOT && (
                  <div className={style.blockConfigRoot}>
                    <div className={style.titleWrapper}>
                      <p className="text-xs font-medium">
                        {t("RegistrationPageAsr.authorizedPerson")}
                      </p>
                    </div>
                    <div className={style.registrationConfigRoot}>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("RegistrationPageAsr.customerNameRu")}*
                          </label>

                          {formik.touched.customerNameRu &&
                            formik.errors.customerNameRu && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.customerNameRu ===
                                    "string"
                                    ? formik.errors.customerNameRu
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="customerNameRu"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.customerNameKz")}*</label>
                          {formik.touched.customerNameKz &&
                            formik.errors.customerNameKz && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.customerNameKz ===
                                    "string"
                                    ? formik.errors.customerNameKz
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="customerNameKz"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.customerPositionRu")}*</label>
                          {formik.touched.customerPositionRu &&
                            formik.errors.customerPositionRu && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.customerPositionRu ===
                                    "string"
                                    ? formik.errors.customerPositionRu
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="customerPositionRu"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                          maxLength={48}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.customerPositionKz")}*</label>
                          {formik.touched.customerPositionKz &&
                            formik.errors.customerPositionKz && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.customerPositionKz ===
                                    "string"
                                    ? formik.errors.customerPositionKz
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="customerPositionKz"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                          maxLength={48}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.organizationPhone")}*</label>
                          {formik.touched.organizationPhone &&
                            formik.errors.organizationPhone && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.organizationPhone ===
                                    "string"
                                    ? formik.errors.organizationPhone
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="organizationPhone"
                          placeholder={t("RegistrationList.organizationPhone")}
                          className={style.placeholder}
                          maxLength={12}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("registrationWithECP.orgEmail")}
                          </label>
                          {formik.touched.organizationEmail &&
                            formik.errors.organizationEmail && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.organizationEmail ===
                                    "string"
                                    ? formik.errors.organizationEmail
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="organizationEmail"
                          placeholder={t('RegistrationList.organizationEmail')}
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.documentBasis")}*</label>
                          {formik.touched.customerBasisTypeId &&
                            formik.errors.customerBasisTypeId && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.customerBasisTypeId ===
                                    "string"
                                    ? formik.errors.customerBasisTypeId
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          value={formik?.values?.docTypeId || null}
                          component={SelectCustomFormik}
                          name="customerBasisTypeId"
                          placeholder={t("documentLabels.choose")}
                          isClearable={true}
                          size="dm"
                          isMulti={false}
                          options={[
                            { value: "0", label: t("RegistrationPageAsr.undefined") },
                            { value: "1", label: t("RegistrationPageAsr.passportRK") },
                            { value: "2", label: t("RegistrationPageAsr.idCardRK") },
                            { value: "3", label: t("RegistrationPageAsr.residencePermitRK") },
                            { value: "4", label: t("RegistrationPageAsr.statelessPersonRK") },
                            { value: "5", label: t("RegistrationPageAsr.registrationCertificate") },
                            { value: "6", label: t("RegistrationPageAsr.foreignPassport") },
                          ]}
                        />
                      </div>
                      {/* <div className={style.inputField1}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                    {t("Документ - основание для подписания документов*")}
                    </label>
                  </div>
                  <Field
                    value={formik?.values?.docTypeId || null}
                    component={SelectCustomFormik}
                    name="docTypeId"
                    placeholder={t("documentLabels.choose")}
                    isClearable={true}
                    size="dm"
                    isMulti={false}
                     options = {[
                      { value: "0", label: "Не определено" },
                      { value: "1", label: "Паспорт РК" },
                      { value: "2", label: "Удостоверение РК" },
                      { value: "3", label: "Вид на жительства РК" },
                      { value: "4", label: "Лицо без гражданства РК" },
                      { value: "5", label: "Регистрационное свидетельство" },
                      { value: "6", label: "Паспорт гражданина иностранного" },
                  ]}
                    onChange={(options: any) => {
                      formik.setFieldValue("docTypeId", options);
                      setInitialValues((prevValues: any) => {
                        return { ...prevValues, docTypeId: options };
                      });
                    }}
                  />
                </div> */}
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.documentBasisNumber")}*</label>
                          {formik.touched.signBasisNumber &&
                            formik.errors.signBasisNumber && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.signBasisNumber ===
                                    "string"
                                    ? formik.errors.signBasisNumber
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="signBasisNumber"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.documentBasisDate")}*</label>
                          {formik.touched.signBasisDate &&
                            formik.errors.signBasisDate && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.signBasisDate ===
                                    "string"
                                    ? formik.errors.signBasisDate
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <DatePickerField
                          name="signBasisDate"
                          placeholder={t("RegistrationPageAsr.choose_date")}
                        />
                      </div>


                    </div>
                  </div>
                )}

                {/* Блок об Адрессе */}
                {!isUOT && (
                  <div className={style.blockConfigRoot}>
                    <div className={style.titleWrapper}>
                      <p className="text-xs font-medium">
                        {t("registrationWithECP.addressDetails")}
                      </p>
                    </div>
                    <div className={style.registrationConfigRoot}>
                      <div className={style.inputField}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("RegistrationPageAsr.city")}*
                          </label>

                          {formik.touched.City &&
                            formik.errors.City && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.City ===
                                    "string"
                                    ? formik.errors.City
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="City"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                        />
                      </div>


                      <div className={style.inputField}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.street")}*</label>
                          {formik.touched.Street &&
                            formik.errors.Street && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Street ===
                                    "string"
                                    ? formik.errors.Street
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Street"
                          placeholder={t('RegistrationList.street')}
                          className={style.placeholder}
                          maxLength={48}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.house")}*</label>
                          {formik.touched.House &&
                            formik.errors.House && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.House ===
                                    "string"
                                    ? formik.errors.House
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="House"
                          placeholder={t('RegistrationList.house')}
                          className={style.placeholder}
                          maxLength={8}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.building")}</label>
                          {formik.touched.Building &&
                            formik.errors.Building && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Building ===
                                    "string"
                                    ? formik.errors.Building
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Building"
                          placeholder={t('RegistrationList.building')}
                          className={style.placeholder}
                          maxLength={8}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.room")}</label>
                          {formik.touched.Premises &&
                            formik.errors.Premises && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Premises ===
                                    "string"
                                    ? formik.errors.Premises
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Premises"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                          maxLength={8}
                        />
                      </div>
                      <div className={style.inputField}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.actualAddressRu")}*</label>
                          {formik.touched.factAddressRu &&
                            formik.errors.factAddressRu && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.factAddressRu ===
                                    "string"
                                    ? formik.errors.factAddressRu
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="factAddressRu"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                          maxLength={80}
                        />
                      </div>
                      <div className={style.inputField}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.actualAddressKz")}*</label>
                          {formik.touched.factAddressKz &&
                            formik.errors.factAddressKz && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.factAddressKz ===
                                    "string"
                                    ? formik.errors.factAddressKz
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="factAddressKz"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                          maxLength={80}
                        />
                      </div>
                      <div className={style.inputField}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.correspondenceAddress")}*</label>
                          {formik.touched.postAddress &&
                            formik.errors.postAddress && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.postAddress ===
                                    "string"
                                    ? formik.errors.postAddress
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="postAddress"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                          maxLength={50}
                        />
                      </div>
                      <div className={style.inputField}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("RegistrationPageAsr.postalCode")}*</label>
                          {formik.touched.Index &&
                            formik.errors.Index && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Index ===
                                    "string"
                                    ? formik.errors.Index
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Index"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                          maxLength={12}
                        />
                      </div>
                      <div
                        className={`${style.inputField} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("RegistrationPageAsr.taxAuthorityCode")}*
                          </label>
                          {formik.touched.refOgd &&
                            formik.errors.refOgd && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.refOgd ===
                                    "string"
                                    ? formik.errors.refOgd
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="refOgd"
                          options={dataOgd}
                          placeholder={t('RegistrationList.ogdCodePlaceholder')}
                          size="sm"
                          isMulti={false}
                        />
                      </div>


                      {/* <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("Значение  адреса*")}</label>
                          {formik.touched.addressValue &&
                            formik.errors.addressValue && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.addressValue ===
                                    "string"
                                    ? formik.errors.addressValue
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="addressValue"
                          placeholder={t('Введите значение адреса')}
                          className={style.placeholder}
                          maxLength={8}
                        />
                      </div> */}


                      <div className={`${style.inputField} ${style.inputFieldNoOwerflow}`}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("RegistrationPageAsr.homeowner")}*
                          </label>
                          {formik.touched.isHomeOwner &&
                            formik.errors.isHomeOwner && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.isHomeOwner ===
                                    "string"
                                    ? formik.errors.isHomeOwner
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="isHomeOwner"
                          options={[{ value: "0", label: t("RegistrationPageAsr.yes") },
                          { value: "1", label: t("RegistrationPageAsr.no") },]}
                          placeholder={t('RegistrationList.ogdCodePlaceholder')}
                          size="sm"
                          isMulti={false}
                        />
                      </div>

                    </div>
                  </div>
                )}

                {/* Блок об счете */}
                {!isUOT && (
                  <div className={style.blockConfigRoot}>
                    <div className={style.titleWrapper}>
                      <p className="text-xs font-medium">{t("registrationWithECP.bankDetails")}</p>
                    </div>
                    <div className={style.registrationConfigRoot}>
                      <div
                        className={`${style.inputField} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("registrationWithECP.bankName")}
                          </label>
                          {formik.touched.refBankCatalog &&
                            formik.errors.refBankCatalog && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.refBankCatalog ===
                                    "string"
                                    ? formik.errors.refBankCatalog
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          noOptionsMessage={noOptionsMessage}
                          component={SelectCustomFormik}
                          name="refBankCatalog"
                          options={dataNameBank}
                          placeholder={t('RegistrationList.ogdCodePlaceholder')}
                          size="sm"
                          isMulti={false}
                          onChange={(selectedOption: any) => {
                            if (selectedOption) {
                              formik.setFieldValue("Bank_Identification_Code", selectedOption);
                            }
                          }}
                        />
                      </div>
                      <div
                        className={`${style.inputField} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("registrationWithECP.bic")}
                          </label>
                          {formik.touched.Bank_Identification_Code &&
                            formik.errors.Bank_Identification_Code && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Bank_Identification_Code ===
                                    "string"
                                    ? formik.errors.Bank_Identification_Code
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="Bank_Identification_Code"
                          options={dataBic}
                          placeholder={t('RegistrationList.bicPlaceholder')}
                          isDisabled={true}
                          size="sm"
                          isMulti={false}
                        />
                      </div>
                      <div className={style.inputField}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("registrationWithECP.accountNumber")}
                          </label>
                          {formik.touched.Checking_Account &&
                            formik.errors.Checking_Account && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Checking_Account ===
                                    "string"
                                    ? formik.errors.Checking_Account
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Checking_Account"
                          placeholder={t('RegistrationList.accountNumberPlaceholder')}
                          className={style.placeholder}
                          maxLength={20}
                        />
                      </div>
                      <div
                        className={`${style.inputField} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("registrationWithECP.kbe")}
                          </label>
                          {formik.touched.kbe &&
                            formik.errors.kbe && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.kbe ===
                                    "string"
                                    ? formik.errors.kbe
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="kbe"
                          placeholder={t('RegistrationList.kbePlaceholder')}
                          className={style.placeholder}
                          maxLength={2}
                        />
                      </div>

                    </div>
                  </div>
                )}

                {/* Документы, удостоверяющие личность руководителя */}
                {!isUOT && (
                  <div className={style.blockConfigRoot}>
                    <div className={style.titleWrapper}>
                      <p className="text-xs font-medium">{t("RegistrationPageAsr.HeadDocuments")}</p>
                    </div>
                    <div className={style.registrationConfigRoot}>
                      {/* <div
                        className={`${style.inputField} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("Тип документа*")}
                          </label>
                          {formik.touched.kbe &&
                            formik.errors.kbe && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.kbe ===
                                    "string"
                                    ? formik.errors.kbe
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="kbe"
                          placeholder={t('Введите значение')}
                          className={style.placeholder}
                          maxLength={2}
                        />
                      </div> */}
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("RegistrationPageAsr.identityDocumentType")}*
                          </label>
                          {formik.touched.docTypeId &&
                            formik.errors.docTypeId && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.docTypeId ===
                                    "string"
                                    ? formik.errors.docTypeId
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>

                        <Field
                          value={formik?.values?.docTypeId || null}
                          component={SelectCustomFormik}
                          name="docTypeId"
                          placeholder={t("documentLabels.choose")}
                          isClearable={true}
                          size="dm"
                          isMulti={false}
                          options={[
                            { value: "0", label: t("RegistrationPageAsr.undefined") },
                            { value: "1", label: t("RegistrationPageAsr.passportRK") },
                            { value: "2", label: t("RegistrationPageAsr.idCardRK") },
                            { value: "3", label: t("RegistrationPageAsr.residencePermitRK") },
                            { value: "4", label: t("RegistrationPageAsr.statelessPersonRK") },
                            { value: "5", label: t("RegistrationPageAsr.registrationCertificate") },
                            { value: "6", label: t("RegistrationPageAsr.foreignPassport") },
                          ]}

                        />
                      </div>
                      <div
                        className={`${style.inputField} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("RegistrationPageAsr.documentNumber")}*
                          </label>
                          {formik.touched.docNumber &&
                            formik.errors.docNumber && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.docNumber ===
                                    "string"
                                    ? formik.errors.docNumber
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="docNumber"
                          placeholder={t('RegistrationPageAsr.choose_option')}
                          className={style.placeholder}
                          maxLength={20}
                        />
                      </div>
                      <div
                        className={`${style.inputField1} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("RegistrationPageAsr.issuedBy")}*
                          </label>
                          {formik.touched.docIssuedBy &&
                            formik.errors.docIssuedBy && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.docIssuedBy ===
                                    "string"
                                    ? formik.errors.docIssuedBy
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="docIssuedBy"
                          placeholder={t("RegistrationPageAsr.choose_option")}
                          className={style.placeholder}
                          maxLength={20}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("RegistrationPageAsr.issueDate")}*
                          </label>
                          {formik.touched.docDateFrom &&
                            formik.errors.docDateFrom && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.docDateFrom ===
                                    "string"
                                    ? formik.errors.docDateFrom
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <DatePickerField
                          name="docDateFrom"
                          placeholder={t("RegistrationPageAsr.choose_date")}
                        />
                      </div>
                      <div
                        className={`${style.inputField1} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("RegistrationPageAsr.validUntil")}*
                          </label>
                          {formik.touched.docDateTo &&
                            formik.errors.docDateTo && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.docDateTo ===
                                    "string"
                                    ? formik.errors.docDateTo
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <DatePickerField
                          name="docDateTo"
                          placeholder={t("RegistrationPageAsr.choose_date")}
                        />
                      </div>

                    </div>
                  </div>
                )}

                {/* Блок об Юзере */}
                {!isUOT && (
                  <div className={style.blockConfigRoot}>
                    <div className={style.titleWrapper}>
                      <p className="text-xs font-medium">{t("registrationWithECP.userDetails")}</p>
                    </div>
                    <div className={style.registrationConfigRoot}>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("registrationWithECP.email")}</label>
                          {formik.touched.Email &&
                            formik.errors.Email && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Email ===
                                    "string"
                                    ? formik.errors.Email
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Email"
                          placeholder={t('RegistrationList.emailPlaceholder')}
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("registrationWithECP.userPhone")}</label>
                          {formik.touched.Phone &&
                            formik.errors.Phone && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Phone ===
                                    "string"
                                    ? formik.errors.Phone
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>

                        <InputMaskWithAny
                          mask="+7 (999) 999-99-99"
                          maskChar="_"
                          type="text"
                          name="Phone"
                          placeholder={t('RegistrationList.userPhonePlaceholder')}
                          className={style.placeholder}
                          value={formik.values.Phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("registrationWithECP.website")}
                          </label>
                          {formik.touched.Organization_Website &&
                            formik.errors.Organization_Website && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Organization_Website ===
                                    "string"
                                    ? formik.errors.Organization_Website
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Organization_Website"
                          placeholder={t('RegistrationList.websitePlaceholder')}
                          className={style.placeholder}
                        />
                      </div>

                      <div
                        className={`${style.inputField1} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            {t("registrationWithECP.selectRole")}
                          </label>
                          {formik.touched.Select_Role &&
                            formik.errors.Select_Role && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Select_Role ===
                                    "string"
                                    ? formik.errors.Select_Role
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="Select_Role"
                          options={authStartInfo.data.roles?.map(
                            (role: any) => ({
                              value: role.roleId,
                              label:
                                role[
                                `name${(i18n.language || "ru") === "kk" ? "Kk" : "Ru"}`
                                ] || role.nameRu,
                            }),
                          )}
                          placeholder={t('RegistrationList.selectRolePlaceholder')}
                          menuPlacement='top'
                          size="sm"
                          isMulti={true}
                          isDisabled={authStartInfo.data.roles?.length < 2}
                        />
                        {/* <Field
                      type="text"
                      name="Select_Role"
                      placeholder="Введите Роль"
                      className={style.placeholder}
                      disabled={true}
                    /> */}
                      </div>
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className={`${style.buttonForm} bg-bg text-gray-600 font-semibold py-2 px-4 h-10 self-end mb-2 rounded-[10px]`}
                      >
                        {t("mainButtons.back")}
                      </button>
                      <button
                        disabled={!isChecked}
                        type="submit"
                        className={`${style.buttonForm} buttonEtrackc bg-green text-white font-semibold py-2 px-4 h-10 self-end mb-2 rounded-[10px]`}
                      >
                        {t("mainButtons.sign")}
                      </button>
                    </div>
                    <div className={style.checkboxWraper}>
                      <label className={style.labelCheckBox}>
                        {t("registrationWithECP.consentToDataProcessing")}
                      </label>
                      <CheckBox
                        id="myCheckbox"
                        checked={isChecked}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}

                {/* блок Физ лица */}

                {isUOT && (
                  <div className={style.blockConfigRoot}>
                    <div className={style.titleWrapper}>
                      <p className="text-xs font-medium">{t("registrationWithECP.userDetails")}</p>
                    </div>
                    <div className={style.registrationConfigRoot}>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("registrationWithECP.firstName")}</label>
                          {formik.touched.Phisical_name &&
                            formik.errors.Phisical_name && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Phisical_name ===
                                    "string"
                                    ? formik.errors.Phisical_name
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Phisical_name"
                          placeholder={t('RegistrationList.firstNamePlaceholder')}
                          className={style.placeholder}
                          disabled={true}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("registrationWithECP.lastName")}</label>
                          {formik.touched.Phisical_surname &&
                            formik.errors.Phisical_surname && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Phisical_surname ===
                                    "string"
                                    ? formik.errors.Phisical_surname
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          disabled={true}
                          type="text"
                          name="Phisical_surname"
                          placeholder={t('RegistrationList.lastNamePlaceholder')}
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("registrationWithECP.middleName")}</label>
                          {formik.touched.Phisical_otcestvo &&
                            formik.errors.Phisical_otcestvo && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Phisical_otcestvo ===
                                    "string"
                                    ? formik.errors.Phisical_otcestvo
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          disabled={true}
                          type="text"
                          name="Phisical_otchestvo"
                          placeholder={t('RegistrationList.middleNamePlaceholder')}
                          className={style.placeholder}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("registrationWithECP.iin")}</label>
                          {formik.touched.Phisical_Iin &&
                            formik.errors.Phisical_Iin && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Phisical_Iin ===
                                    "string"
                                    ? formik.errors.Phisical_Iin
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          disabled={true}
                          type="text"
                          name="Phisical_Iin"
                          placeholder={t('RegistrationList.iinPlaceholder')}
                          className={style.placeholder}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("registrationWithECP.email")}</label>
                          {formik.touched.Email &&
                            formik.errors.Email && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Email ===
                                    "string"
                                    ? formik.errors.Email
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Email"
                          placeholder={t('RegistrationList.emailPlaceholder')}
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("registrationWithECP.userPhone")}</label>
                          {formik.touched.Phone &&
                            formik.errors.Phone && (
                              <div className={style.tooltipWrapper}>
                                <FaExclamationCircle className={style.errorIcon} />
                                <div className={style.tooltipText}>
                                  {typeof formik.errors.Phone ===
                                    "string"
                                    ? formik.errors.Phone
                                    : t(
                                      "notification_from_third_countries.error_customs_clearance_reg_date",
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Phone"
                          placeholder={t('RegistrationList.userPhonePlaceholder')}
                          className={style.placeholder}
                        />
                      </div>
                      {/* <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>{t("registrationWithECP.position")}</label>
                          {formik.touched.Phone &&
                        formik.errors.Phone && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.Phone ===
                              "string"
                                ? formik.errors.Phone
                                : t(
                                    "notification_from_third_countries.error_customs_clearance_reg_date",
                                  )}
                            </div>
                          </div>
                        )}
                        </div>
                        <Field
                          type="text"
                          name="Phone"
                          placeholder={t('RegistrationList.userPhonePlaceholder')}
                          className={style.placeholder}
                        />
                      </div> */}

                      {/* <div
                        className={`${style.inputField1} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                          {t("registrationWithECP.roleSelection")}
                          </label>
                          {formik.touched.Select_Role &&
                        formik.errors.Select_Role && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.Select_Role ===
                              "string"
                                ? formik.errors.Select_Role
                                : t(
                                    "notification_from_third_countries.error_customs_clearance_reg_date",
                                  )}
                            </div>
                          </div>
                        )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="Select_Role"
                          options={authStartInfo.data.roles?.map(
                            (role: any) => ({
                              value: role.roleId,
                              label:
                                role[
                                `name${(i18n.language || "ru") === "kk" ? "Kk" : "Ru"}`
                                ] || role.nameRu,
                            }),
                          )}
                          placeholder={t('RegistrationList.selectRolePlaceholder')}
                          size="sm"
                          isMulti={true}
                          isDisabled={authStartInfo.data.roles?.length < 2}
                        />
                      </div> */}
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className={`${style.buttonForm} bg-bg text-gray-600 font-semibold py-2 px-4 h-10 self-end mb-2 rounded-[10px]`}
                      >
                        {t("mainButtons.back")}
                      </button>
                      <button
                        disabled={!isChecked}
                        type="submit"
                        className={`${style.buttonForm} buttonEtrackc bg-green text-white font-semibold py-2 px-4 h-10 self-end mb-2 rounded-[10px]`}
                      >
                        {t("mainButtons.sign")}
                      </button>
                    </div>
                    <div className={style.checkboxWraper}>
                      <label className={style.labelCheckBox}>
                        {t("registrationWithECP.consentToDataProcessing")}
                      </label>
                      <CheckBox
                        id="myCheckbox"
                        checked={isChecked}
                        onChange={handleChange}
                      />
                      <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
        {isLoading && (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Registration;
