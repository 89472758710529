import { ChangingLanguage } from "../../shared/components/ChangingLanguage";
import { useTranslation } from "react-i18next";
import Client from "@seithq/ncalayer";
import { useEffect, useState } from "react";
// @ts-ignore
import { NCALayerClient } from "ncalayer-js-client";
import ModalComponent from "../../shared/Ui/ModalComponent/ModalWindow";
import { SelectCustom } from "../../shared/Ui/SelectCustom/SelectCustom";
import { useAppDispatch, useAppSelector } from "../../shared/hook";
import { login, updateOrganizationData } from "./../../shared/slice/authSlice";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { authenticate, getRoleMatrix, userInfo } from "../../common/api/authApi";
import AlertBox from "../../shared/Ui/AlertBox/AlertBox";
import ModalButton from "../../shared/Ui/Buttons/ModalButtons";
import { normalizedLocale } from "../../common/libs/normalizedLocale";
import { saveRoleState } from "../../shared/slice/roleMatrix";
import { roleMatrix } from './../../shared/slice/roleMatrix';
import { goToEtrack } from "../../common/api/nktApi";
declare global {
  interface Window {
    browseKeyStore?: () => void;
  }
}

const SignIn = () => {
  const { t, i18n } = useTranslation();
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [errorModal, setErrorModal] = useState<any>(null)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = normalizedLocale(i18n.language);

  async function connectAndSignXML() {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
      setAlertOpen({
        status: 'error',
        message: "Не удалось подключиться к NCALayer"
      })
      return;
    }

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const xmlData = `<time>${currentTimeInSeconds}</time>`;
    let xmlSignature;

    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xmlData,
        NCALayerClient.basicsXMLParams,
        process.env.REACT_APP_ETRACK_TEST === "true" ? NCALayerClient.basicsSignerTestAny : NCALayerClient.basicsSignerAny,
        // basicsSignerSignAny
        "ru",
      );
      console.log("Подпись XML:", xmlSignature);
    } catch (error) {
      console.log("Ошибка при выполнении XML-подписи:", error);
      return;
    }

    try {
      const response = await authenticate(xmlSignature[0]);
      const currentTime = Math.floor(Date.now() / 1000);
      const expires = response.data.refresh_expires_in ? currentTime + response.data.refresh_expires_in : 1;

      // Получаем текущую дату
      const currentDate = new Date();

      // Добавляем 1 месяц к текущей дате
      const oneMonthLater = new Date(currentDate.setMonth(currentDate.getMonth() + 1));

      // Устанавливаем куки с временем жизни 1 месяц
      Cookies.set("access_token", response.data.access_token, {
        expires: oneMonthLater,
        secure: true,
      });
      Cookies.set("id_token", response.data.id_token, {
        expires: oneMonthLater,
        secure: true,
      });
      Cookies.set("refresh_token", response.data.refresh_token, {
        expires: oneMonthLater,
        secure: true,
      });
      Cookies.set("token_expiry", expires);

      const userInfoResponse = await userInfo(response.data.access_token);
      console.log("User info success", userInfoResponse);
      dispatch(updateOrganizationData(userInfoResponse));

      const roleMatrix = await getRoleMatrix();
      dispatch(saveRoleState(roleMatrix.data));

      dispatch(login());

      navigate("/main");
    } catch (error: any) {
      console.error("Authentication failed:", error);
      setErrorModal({
        hasError: true,
        errorMessage: error.response?.data?.message ? error.response?.data?.message : "Произошла непредвиденная ошибка, попробуйте позже"
      })
    }
  }


  const siginTest = () => {

  };

  return (
    <div className="bg-bg flex items-center justify-center h-screen">
      {errorModal?.hasError && (
        <ModalComponent
          isOpen={errorModal?.hasError}
          onClose={() => {
            setErrorModal(null);
          }}
          headerContent={ <h1 className="h__modal">
            {t("documentNotification.notification")}
          </h1>}
          content={
            <p>
                {" "}
                {`${errorModal?.errorMessage ? errorModal?.errorMessage : "Попробуйте позже."}`}
              </p>
          }
          customContent={
            <ModalButton
              type="button"
              onClick={() => {
                setErrorModal(null);
              }}
            >
              {t("mainButtons.accept")}
            </ModalButton>
          }
        />
      )}
      <div className="flex flex-col justify-between h-[300px] bg-white p-8 rounded-lg shadow-lg w-96 mx-10">
        <a href="/" className="flex justify-center mb-4">
          <img
            src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
            alt="E-Track Logo"
            className="h-12"
          />
        </a>
        <div className="flex flex-col space-y-4">
          <button
            onClick={connectAndSignXML}
            className="bg-green text-white font-semibold py-2 px-4 rounded"
          >
            {t("authorization.enter-ecp")}
          </button>
          {/* <button
  onClick={async () => {
    const token = prompt('Введите ваш токен:'); // Показать диалог ввода
    if (token) { // Проверка, что токен не пустой
      try {
        await goToEtrack(token); // Попробовать выполнить запрос
      } catch (error: any) {
        console.error(`Error: ${error.message}`); // Логирование ошибки
      } finally {
        window.location.href = "https://etrack-dev.edge-apps.com/profile"; // Перенаправление
      }
    } else {
      alert('Токен не был введен');
    }
  }}
  className="bg-green text-white font-semibold py-2 px-4 rounded"
>
  Тест нкт
</button> */}



        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-end">
            {/* <button
              onClick={() => siginTest()}
              className="text-gray-600 text-sm"
            >
              {t("authorization.forgetPasword")}
            </button> */}
            <div className="relative">
              <div>
                <ChangingLanguage onlyDesktop={true} />
              </div>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertBox
        isOpen={alertOpen}
        setIsOpen={setAlertOpen}
      />
    </div>

  );
};

export default SignIn;
