import React from "react";
import { useField } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru"; // Импортируем русскую локализацию
import style from "./dataPicker.module.css";

// Регистрируем русскую локализацию
registerLocale("ru", ru as any);

interface DatePickerFieldProps {
  name: string;
  placeholder: string;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  name,
  placeholder,
}) => {
  const [field, , helpers] = useField(name);

  return (
    <div className={style.datePickerWrapper}>
      <DatePicker
        selected={field.value ? new Date(field.value) : null}
        onChange={(date) =>
          helpers.setValue(date ? date.toISOString().split("T")[0] : "")
        }
        dateFormat="yyyy-MM-dd"
        placeholderText={placeholder}
        className={style.datePickerInput}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale="ru" // Устанавливаем русскую локализацию
      />
    </div>
  );
};

export default DatePickerField;
