import axios, { AxiosResponse } from "axios";
import apiClient from "./apiClient";
import i18n from "../../i18n";

interface AuthResponse {
  // Укажите ожидаемый тип ответа от сервера
}

// Функция для авторизации с использованием signedXML
export const checkUser = async (signedXML: string): Promise<any> => {
  try {
    const requestBody = {
      signedXML: signedXML,
    };

    const response: AxiosResponse<AuthResponse> = await axios.post(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/check`,
      requestBody,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

// Создание Юзера

export const createUser = async (signedXML: string): Promise<any> => {
  try {
    const requestBody = {
      signedXML: signedXML,
    };

    const response: AxiosResponse<AuthResponse> = await axios.post(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/registration`,
      requestBody,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};


// Функция для registration с Главы продвинутая
export const createHead = async (signedXML: string, cms:string): Promise<any> => {
  try {
    const requestBody = {
      signedXML: signedXML,
      cms: cms,
    };

    const response: AxiosResponse<AuthResponse> = await axios.post(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/registration-head`,
      requestBody,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const fetchBankCatalogList = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_REFERENCE_SERVICE}/reference/bank-catalog/list`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching bank catalog list:", error);
    throw error;
  }
};
