import axios, { AxiosResponse } from "axios";
import { GetResponse, PostResponse } from "./response";
import i18n from "../../i18n";
import apiClient from "./apiClient";

export const getClientData = async (): Promise<any> => {
    console.log("ASR Service URL:", process.env.REACT_APP_URL_ASR_SERVICE)
    try {
      const response: AxiosResponse<any> = await apiClient.get(
      `${process.env.REACT_APP_URL_ASR_SERVICE}/asr/get`,
      );      
      return response.data;
    } catch (error) {
      console.error("Error fetching product card:", error);
      throw error;
    }
  };

  export const getBalance = async (): Promise<any> => {
    console.log("ASR Service URL:", process.env.REACT_APP_URL_ASR_SERVICE)
    try {
      const response: AxiosResponse<any> = await apiClient.get(
      `${process.env.REACT_APP_URL_ASR_SERVICE}/asr/get-balance`,
      );      
      return response.data;
    } catch (error) {
      console.error("Error fetching product card:", error);
      throw error;
    }
  };

  export const regBalance = async (): Promise<any> => {
    console.log("ASR Service URL:", process.env.REACT_APP_URL_ASR_SERVICE)
    try {
      const response: AxiosResponse<any> = await apiClient.get(
      `${process.env.REACT_APP_URL_ASR_SERVICE}/asr/re-register`,
      );      
      return response.data;
    } catch (error) {
      console.error("Error fetching product card:", error);
      throw error;
    }
  };