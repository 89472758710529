import { PaginationState } from "@tanstack/react-table";
import classNames from "classnames";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import DocumentsService, {
  viewAndPrintDocument,
} from "../../../common/api/documentsApi";
import { setBlocker, clearBlocker } from '../../../shared/slice/blockerSlice'
import ModalButton from "../../../shared/Ui/Buttons/ModalButtons";
import { EmptyListPlaceholderContent } from "../../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../../shared/Ui/ModalComponent/ModalWindow";
import Stepper from "../../../shared/components/Stepper/Stepper";
import Pagination from "../../../shared/components/Table/Pagination";
import { ITable } from "../../../shared/components/Table/types";
import { AddByOrderNumber } from "../Upload/AddByOrderNumber/AddByOrderNumber";
import { DocumentUpload } from "../Upload/DocumentUpload/DocumentUpload";
import DocumentsSearch from "../Upload/DocumentsSearch/DocumentsSearch";
import MarkScan from "../Upload/MarkScan/MarkScan";
import style from "./DocumentTransferAcceptance.module.css";
// @ts-ignore
import { createDocument } from "../../../common/api/notificationApi";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import AlertBox from "../../../shared/Ui/AlertBox/AlertBox";
import CheckBox from "../../../shared/Ui/CheckBox/CheckBox";
import Loader from "../../../shared/Ui/Loader/Loader";
import StatusBadge from "../../../shared/Ui/StatusBadge/StatusBadge";
import { useAppDispatch, useAppSelector } from "../../../shared/hook";
import { submitXmlProduct } from "../../../shared/signature/documentsSignature";
import { deleteDataDocument } from "../../../shared/slice/documentState";
import CheckResult from "../Upload/CheckResults/CheckResult";
import { AcceptXmlProduct, RevokeAct } from "./utils/signature";
import PageNotRole from "../../../shared/Ui/PageNotFound/PageNotRole";
import { ModalResult } from "../../../shared/components/ModalResult/ModalResult";
import { getDescription, getStatusType } from "../../../shared/documentUtils/dictionary";

function DocumentTransferAcceptanceOrders() {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.auth.userData);
  const documentState = useAppSelector((state) => state.documentState);
  const roleMatrix = useAppSelector((state) => state.roleMatrix);
  const [role, setRole] = useState<any>(null);
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);
  const navigate = useNavigate();
  const { transferAcceptanceDocumentId } = useParams();
  const [tableData, setTableData] = useState<ITable>();
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [errorModal, setErrorModal] = useState<any>(null);
  const [resModal, setResModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(null);
  const [isOrderNumberModal, setIsOrderNumberModal] = useState<boolean>(false);
  const [isDocumentSearchModalOpen, setIsDocumentSearchModalOpen] =
    useState<boolean>(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] =
    useState<boolean>(false);
  const [isMarkScanModal, setIsMarkScanModal] = useState<boolean>(false);
  const [isCheckOpen, setIsCheckOpen] = useState<any>(null);
  const [status, setStatus] = useState<any>("new");
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [toggleOnLoad, setToggleOnLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteMarks, setDeleteMarks] = useState<number[]>([]);
  const [isResultsLoading, setIsResultsLoading] = useState(false);

  const [statusType, setStatusType] = useState({
    type: "new",
    label: t("documentStatus.new"),
  });

  const [initialValues, setInitialValues] = useState<any>({
    receiver: null,
    refOperationType: null,
    note: null, // Примечание
    hasPaperInfo: false,
    documentPaperNumber: null, // Hoмер бум. документа
    documentPaperAt: null, //Дата бум. документа

    id: null, // ИД
    docNumber: null, // Номер документа

    orgId: "",
    createUser: "",
    createdAt: "",
    // updatedAt: "",
    // signingAt: "",

    sender: "",
    status: "new",
    isAddingStarted: false,
  });

  useEffect(() => {
    const roleObj = () => {
      let roleWithSpecificAction = roleMatrix?.roleMatrix?.find((item) => item.id === 12)
      let roleDraft = roleMatrix?.roleMatrix?.find((item) => item.id === 6);
      if (!roleWithSpecificAction.view) {
        roleWithSpecificAction = roleMatrix?.roleMatrix?.find((item) => item.id === 5)
      }
      return {
        roleDoc: roleWithSpecificAction?.specificAction?.documents?.[1] || false,
        roleDraft: roleDraft || false,
        roleAccepted: roleWithSpecificAction || false
      };
    }

    setRole(roleObj)

    onLoad();
    setIsLoading(false)
    if (documentState.isSafe) {
      setIsFormChanged(true);
    }
  }, [
    pagination,
    isOrderNumberModal,
    isDocumentSearchModalOpen,
    isMarkScanModal,
    isCheckOpen,
    CheckResult,
    navigate,
    toggleOnLoad,
  ]);

  async function onLoad() {
    if (transferAcceptanceDocumentId !== undefined) {
      try {
        const marksResponse = await DocumentsService.getDocMarksList(
          transferAcceptanceDocumentId,
          pagination?.pageIndex + 1,
          pagination.pageSize
        );
        if (marksResponse) {
          setTableData({
            values: marksResponse?.products,
            currentPage: marksResponse?.currentPage,
            itemsPerPage: marksResponse?.itemsPerPage,
            totalItems: marksResponse?.totalItems,
            totalPages: marksResponse?.totalPages,
          });
        } else {
          setTableData({
            values: [],
            currentPage: 0,
            itemsPerPage: 0,
            totalItems: 0,
            totalPages: 0,
          });
        }

        const documentResponse = await DocumentsService.getDocument(transferAcceptanceDocumentId);
        const { type, label } = getStatusType(documentResponse.data.status, t);
        setStatusType({ type, label });
        setInitialValues(documentResponse.data);
        setStatus(documentResponse.data.status);

        if (documentResponse.data.isAddingStarted) {
          const repeatRequest = async () => {
            const resp = await DocumentsService.getDocument(transferAcceptanceDocumentId);
            setInitialValues(resp.data);

            if (resp.data.isAddingStarted) {
              setTimeout(repeatRequest, 2000);
            }
            else {
              onLoad()
            }
          };

          setTimeout(repeatRequest, 2000);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setTableData({
          values: [],
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        });
        navigate("/404");
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }

  async function submitCreate() {
    const backendData = {
      ...documentState.documentData,
      refDocType: 5,
      id: transferAcceptanceDocumentId || null,
    };
    if (transferAcceptanceDocumentId && documentState.isSafe) {
      await DocumentsService.editDocument(backendData)
        .then((response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          dispatch(deleteDataDocument());
          onLoad();
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_edited_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_edit_error"),
          });
        });
    }
    if (!transferAcceptanceDocumentId) {
      const create = await createDocument(backendData)
        .then((response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          dispatch(deleteDataDocument());
          navigate(
            `/documents/transfer-acceptance/${response.data.document.id}/orders`,
          );
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_saved_success"),
          });
        })
        .catch((error) => {
          console.error(123);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_save_error"),
          });
        });
    }
  }

  const getDocument = async () => {
    try {
      if (transferAcceptanceDocumentId) {
        setIsLoading(true);

        // Проверка значения перед скачиванием
        console.log("Идентификатор документа:", transferAcceptanceDocumentId);
        console.log("Номер документа:", initialValues?.docNumber);

        const fileBlob = await DocumentsService.downloadDocumentMarks(
          transferAcceptanceDocumentId,
        );

        const downloadUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        const fileName = `${initialValues?.docNumber}.xlsx`;

        // Логирование для проверки имени файла
        console.log("Имя файла для скачивания:", fileName);

        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }

      }
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const checkboxDelete = (id: any) => {
    setDeleteMarks((prevDeleteMarks: any) => {
      if (prevDeleteMarks.includes(id)) {
        return prevDeleteMarks.filter((item: any) => item !== id);
      } else {
        return [...prevDeleteMarks, id];
      }
    });
  };

  const fullDeleteMarks = () => {
    setDeleteMarks([]);
    if (tableData?.values) {
      const allIds = tableData.values.map((value: any) => value?.markId);
      setDeleteMarks(allIds);
    }
    if (deleteMarks?.length === tableData?.values?.length) {
      setDeleteMarks([]);
    }
  };

  const handleDownloadClick = async () => {
    try {
      setIsLoading(true);
      const fileBlob = await viewAndPrintDocument(transferAcceptanceDocumentId);
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `document_${transferAcceptanceDocumentId}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (docId?: string, marks?: string[]) => {
    if (typeof docId !== undefined && marks) {
      DocumentsService.deleteMarks(docId, marks)
        .then((response) => {
          if (
            tableData?.currentPage === tableData?.totalPages &&
            deleteMarks?.length === tableData?.values?.length
          ) {
            setPagination((prevState) => ({
              ...prevState,
              pageIndex:
                prevState.pageIndex === 0
                  ? prevState.pageIndex
                  : prevState.pageIndex - 1,
            }));
          }
          setDeleteMarks([]);
        })
        .catch((error) => {
          setErrorModal(
            `${error?.response?.data?.message || t("Произошла ошибка при выгрузке файла")}`,
          ),
            console.log(`Error deleting mark from document:" ${error}`)
        }
        )
        .finally(() => {
          setIsDeleteModalOpen(null);
          onLoad();
        });
    } else {
      setErrorModal(t("documentNotification.no_id_error"));
      setIsDeleteModalOpen(null);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue = t("documentNotification.unsaved_changes_warning");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormChanged]);
  const { isBlocked, proceed } = useAppSelector((state) => state.blocker);
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked !== false && // и если форма изменена

      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes("transfer-acceptance"),
  );

  if (!role || !role?.roleAccepted?.view) {
    return (
      <PageNotRole />
    )
  }
  // console.log(3332213, role.roleDoc.actions[1].active, role.roleDoc);

  if (isLoading && !role) return <Loader />;

  const closeModal = () => {
    setResModal(false)
  }

  const navigateTo = 'sent'

  return (
    <div className="max-w-[1585px] w-full m-t-[20px]">
      {isBlocked && blocker.state === "blocked" ? (
        <ModalComponent
          isOpen={true} // Модальное окно всегда открыто, когда blocker.state === "blocked"
          onClose={() => {
            if (blocker && typeof blocker.reset === "function") {
              blocker.reset();
            } else {
              console.error("Reset function is not available.");
            }
          }}
          headerContent={
            <>
              <span>{t("documentNotification.notification")}</span>
              <button
                type="button"
                onClick={() => {
                  if (blocker && typeof blocker.reset === "function") {
                    blocker.reset();
                  } else {
                    console.error("Reset function is not available.");
                  }
                }}
                aria-label="Close"
                className="float-right cursor-pointer bg-none border-none"
              >
                &times; {/* Символ крестика */}
              </button>
            </>
          }
          content={<p>{t("documentNotification.unsaved_changes_warning")}</p>}
          customContent={
            <>
              <ModalButton
                type="button"
                onClick={() => {
                  dispatch(clearBlocker())
                  if (blocker && typeof blocker.proceed === "function") {
                    blocker.proceed(); // Уходим со страницы без сохранения
                  } else {
                    console.error("Proceed function is not available.");
                  }
                }}
              >
                {t("mainButtons.cancel")}
              </ModalButton>
              <ModalButton
                type="button"
                color="green"
                onClick={async () => {
                  dispatch(clearBlocker())
                  try {
                    await submitCreate(); // Сохранение данных
                    if (blocker && typeof blocker.proceed === "function") {
                      blocker.proceed(); // Переход после сохранения
                    }
                  } catch (error) {
                    console.error("Error saving data:", error);
                    // Можно обработать ошибку, например, показать уведомление пользователю
                  }
                }}
              >
                {t("mainButtons.save")}
              </ModalButton>
            </>
          }
        />
      ) : null}
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
          <div>
            {transferAcceptanceDocumentId ? (
              <div className="flex gap-[20px]">
                <div className="flex flex-col">
                  <StatusBadge type={statusType.type}>
                    {t("documentLabels.status") + " " + statusType.label}
                  </StatusBadge>
                  {(initialValues?.signingAt || initialValues?.createdAt) && (
                    <div className="text-[14px] font-semibold text-gray-700 ml-[9px]">
                      {format(
                        new Date(
                          initialValues?.signingAt || initialValues?.createdAt,
                        ),
                        "dd.MM.yyyy, HH:mm",
                      )}
                    </div>
                  )}
                </div>
                <span className="flex gap-[10px]">
                  <div>{getDescription(initialValues?.refDocType, t)}:</div>
                  <div>
                    {initialValues?.docNumber
                      ? initialValues?.docNumber
                      : " " + t("mainLabels.not_generated")}
                  </div>
                </span>
              </div>
            ) : (
              <div className="flex gap-[20px]">
                <StatusBadge type={statusType.type}>
                  {t("documentLabels.status") + " " + statusType.label}
                </StatusBadge>
                {t("documentLabels.create_document")}
                {": "}
                {t("documentType.goods_transfer_act")}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-between ">
          <div className="my-auto">
            <Stepper currentStep={2} />
          </div>

          <div className="flex flex-row gap-[20px] flex-wrap">
            {transferAcceptanceDocumentId &&
              (status === "SIGNED_BY_RECIPIENT") &&
              userData.iinBin !== initialValues?.sender &&
              tableData?.totalItems &&
              tableData?.totalItems > 0 &&
              role.roleDoc.actions[0].active ? (
              <button
                className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                type="button"
                onClick={() => getDocument()}
              >
                {t("documentLabels_3.download_marks")}
              </button>
            ) : null}

            <button
              className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => {
                dispatch(deleteDataDocument());
                if (initialValues.status == "DRAFT") {
                  console.log("Navigating to drafts");
                  navigate("/documents/drafts");
                } else if (userData.iinBin !== initialValues.receiver) {
                  console.log("Navigating to sent");
                  navigate("/documents/sent");
                } else {
                  console.log("Navigating to inbox");
                  navigate("/documents/inbox");
                }

              }}
            >
              {t("mainButtons.close")}
            </button>
            {status !== "DRAFT" &&
              status !== "new" &&
              status === "SIGNED_BY_RECIPIENT" &&
              role.roleDoc.actions[1].active && (
                <div className="flex flex-row gap-[20px] flex-wrap">
                  <button
                    className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                    type="button"
                    onClick={() => handleDownloadClick()}
                  >
                    {t("mainButtons.print")}
                  </button>
                </div>
              )}
            <button
              className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
              type="button"
              onClick={() =>
                transferAcceptanceDocumentId
                  ? navigate(
                    `/documents/transfer-acceptance/${transferAcceptanceDocumentId}`,
                  )
                  : navigate(`/documents/transfer-acceptance/new`)
              }
            >
              {t("mainButtons.back")}
            </button>
            {status == "IN_CONFIRMATION" && role.roleDraft.edit ? (
              <div className="flex flex-row gap-[20px] flex-wrap">
                {initialValues.sender === userData.iinBin ? (
                  <button
                    className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                    type="button"
                    onClick={() =>
                      RevokeAct(
                        transferAcceptanceDocumentId,
                        setErrorModal,
                        navigate,
                      )
                    }
                  >
                    {t("mainButtons_2.revoke")}
                  </button>
                ) : (
                  <>
                    <button
                      className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded buttonEtrackc"
                      type="button"
                      onClick={() =>
                        AcceptXmlProduct(
                          transferAcceptanceDocumentId,
                          "REJECT",
                          setErrorModal,
                          navigate,
                          setAlertOpen,
                          setIsCheckOpen,
                        )
                      }
                    >
                      {t("mainButtons_2.reject")}
                    </button>
                    <button
                      className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                      type="button"
                      onClick={() =>
                        AcceptXmlProduct(
                          transferAcceptanceDocumentId,
                          "ACCEPT",
                          setErrorModal,
                          navigate,
                          setAlertOpen,
                          setIsCheckOpen,
                        )
                      }
                    >
                      {t("mainButtons.accept")}
                    </button>
                  </>
                )}
              </div>
            ) : (
              <>
                {((status === "new" && role.roleDraft.edit) ||
                  (status == "DRAFT" && role.roleDraft.edit)) && (
                    <div className="flex flex-row gap-[20px] flex-wrap">
                      <button
                        className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                        type="button"
                        onClick={() => submitCreate()}
                      >
                        {t("mainButtons.save")}
                      </button>
                    </div>
                  )}
                {(status == "DRAFT" && role.roleDraft.specificAction.actions[0].active) && (
                  <div className="flex flex-row gap-[20px] flex-wrap">
                    <button
                      className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                      type="button"
                      onClick={() =>
                        submitXmlProduct(
                          transferAcceptanceDocumentId,
                          navigate,
                          setAlertOpen,
                          setIsLoading,
                          setErrorModal,
                          setIsCheckOpen,
                          t,
                          navigateTo,
                        )
                      }
                    >
                      {t("mainButtons.sign")}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col bg-white  my-3 px-5 pb-3 relative rounded-xl shadow-md  gap-[20px]">
          <div className=" my-5 relative flex flex-row align-center gap-x-[20px]">
            {((status === "new" && role.roleDraft.edit) ||
              (status == "DRAFT" && role.roleDraft.edit)) && (
                <>
                  {!initialValues.isAddingStarted
                    ?
                    <button
                      onClick={() => {
                        submitCreate();
                      }}
                      className={`text-white font-semibold py-2 px-4 rounded ${initialValues.isAddingStarted ? "bg-gray-500 cursor-not-allowed" : "bg-green"
                        }`}
                      data-tooltip-offset={4}
                      data-tooltip-id="document-type"
                    >
                      {t("documents_orders.add_products")}
                    </button>
                    : null}
                  {status !== "new" &&
                    <button
                      onClick={() =>

                        setResModal(true)
                      }
                      disabled={initialValues.isAddingStarted}
                      className="bg-green text-white font-semibold py-2 px-4 rounded flex items-center justify-center"
                    >
                      {initialValues.isAddingStarted ? (
                        <div className="flex items-center gap-[10px]">
                          <span className="text-white">Результаты проверки</span>
                          <div className={style.loaderContainer}>
                            <div className={style.loader}></div>
                          </div>
                        </div>
                      ) : (
                        <span className="text-white">Результаты проверки</span>
                      )}
                    </button>
                  }
                  {deleteMarks?.length > 0 && (
                    <button
                      onClick={() => setIsDeleteModalOpen(deleteMarks)}
                      className=" bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                    >
                      {t("documents_orders.delete_products")}
                    </button>
                  )}
                  <div className="z-[1000] relative ">
                    <Tooltip
                      id="document-type"
                      className={classNames(
                        style.wrapperTooltip,
                        "drop-shadow-2xl",
                      )}
                      openOnClick={true}
                      clickable={true}
                      noArrow={true}
                      opacity={1}
                      place={"bottom-end"}
                    >
                      <div
                        className={classNames(
                          style.tooltip,
                          "flex flex-col min-w-[208px] p-0 rounded-lg relative left-[21px] overflow-hidden",
                        )}
                      >
                        <button
                          type="button"
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsFileUploadModalOpen(true)}
                        >
                          <span>{t("documents_orders.upload_from_file")}</span>
                        </button>
                        <button
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsDocumentSearchModalOpen(true)}
                        >
                          <span>{t("documents_orders.add_codes")}</span>
                        </button>
                        <button
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsMarkScanModal(true)}
                        >
                          <span>{t("mark_scan.scan_codes")}</span>
                        </button>
                        <button
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsOrderNumberModal(true)}
                        >
                          <span>{t("documents_orders.by_order_number")}</span>
                        </button>
                      </div>
                    </Tooltip>
                  </div>
                </>
              )}
          </div>
          <div className="w-full">
            <div className="rounded-lg overflow-x-auto bg-white">
              <table className="max-w-full w-full leading-normal">
                <thead className="border-b-2 border-gray-300">
                  <tr>
                    {status === "DRAFT" && tableData?.totalItems !== 0 && (
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        <CheckBox
                          checked={
                            deleteMarks?.length === tableData?.values?.length
                          }
                          onChange={() => {
                            fullDeleteMarks();
                          }}
                        />
                      </th>
                    )}
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      № {t("documents_orders.number")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.code")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.ntin")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.order_number")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.product_name")}
                    </th>
                  </tr>
                </thead>
                {tableData?.values?.length === 0 || !tableData?.values ? (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center py-5">
                        <div>
                          <EmptyListPlaceholderContent
                            title={t("documents_orders.no_data_title")}
                            description={t(
                              "documents_orders.no_data_description",
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="font-normal text-center">
                    {tableData?.values?.map((value: any, index: any) => (
                      <tr key={`tr-${index}-${value}`}>
                        {((status === "new" && role.roleDraft.edit) ||
                          (status == "DRAFT" && role.roleDraft.edit)) && (
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <CheckBox
                                id="myCheckbox"
                                checked={deleteMarks.includes(value?.markId)}
                                onChange={() => checkboxDelete(value?.markId)}
                              />
                            </td>
                          )}
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {index + 1 || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.markCode || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.productTypeCode || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.orderNumber || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.productName || "-"}
                        </td>
                        {((status === "new" && role.roleDraft.edit) ||
                          (status == "DRAFT" && role.roleDraft.edit)) && (
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <img
                                src="/icons/trash.svg"
                                className="cursor-pointer min-w-[20px]"
                                onClick={() =>
                                  setIsDeleteModalOpen([value?.markId])
                                }
                              ></img>
                            </td>
                          )}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <Pagination
              totalItems={tableData?.totalItems || 0}
              totalPages={tableData?.totalPages || 0}
              pagination={pagination}
              setPagination={setPagination}
            />
          </div>
          <ModalComponent
            isOpen={!!isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(null)}
            headerContent={
              <h1 className="h__modal">
                {t("documentNotification.notification")}
              </h1>
            }
            content={
              <p className="p__modal">{t("documents_orders.confirm_delete")}</p>
            }
            customContent={
              <div className="flex flex-row gap-[10px]">
                <ModalButton
                  type="button"
                  color="grey"
                  onClick={() => setIsDeleteModalOpen(null)}
                >
                  {t("mainButtons.cancel")}
                </ModalButton>
                <ModalButton
                  // color="green"
                  type="button"
                  onClick={() => {
                    isDeleteModalOpen &&
                      handleDelete(
                        transferAcceptanceDocumentId,
                        isDeleteModalOpen,
                      );
                    setIsDeleteModalOpen(null);
                    onLoad();
                  }}
                >
                  {t("mainButtons.delete")}
                </ModalButton>
              </div>
            }
          />
          <ModalComponent
            isOpen={errorModal}
            onClose={() => setErrorModal(null)}
            headerContent={
              <h1 className="h__modal">
                {t("documentNotification.notification")} -{" "}
                {t("documentNotification.check_fields")}:
              </h1>
            }
            content={
              <div className="error-list">
                <ul>
                  {errorModal && errorModal?.length > 0 ? (
                    errorModal.map((error: any, index: any) => (
                      <li
                        className="border-b border-gray-300 py-1"
                        key={index}
                      >
                        {t(`error_dictionary.${error}`)}
                      </li>
                    ))
                  ) : (
                    <li>{t("documentNotification.no_errors")}</li>
                  )}
                </ul>
              </div>
            }
            customContent={
              <ModalButton
                type="button"
                onClick={() => {
                  setErrorModal(null);
                }}
              >
                {t("mainButtons.accept")}
              </ModalButton>
            }
          />
          <ModalComponent
            isOpen={errorModal?.singleError}
            onClose={() => setErrorModal(null)}
            headerContent={
              <h1 className="h__modal">
                {" "}
                {t("documentNotification.notification")}
              </h1>
            }
            content={
              <div className="error-list">
                <p className="border-b border-gray-300 py-1">
                  {t("documentStatus.error")}:{" "}
                  {errorModal?.errorMessage}
                </p>
              </div>
            }
            customContent={
              <ModalButton
                type="button"
                onClick={() => {
                  setErrorModal(null);
                }}
              >
                {t("mainButtons.accept")}
              </ModalButton>
            }
          />
          <AddByOrderNumber
            isOpen={isOrderNumberModal}
            setIsOpen={setIsOrderNumberModal}
            documentId={transferAcceptanceDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
            setErrorModal={setErrorModal}
          />
          <ModalResult
            documentId={transferAcceptanceDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
            setErrorModal={setErrorModal}
            resModal={resModal}
            closeModal={closeModal}
          />
          <DocumentsSearch
            isOpen={isDocumentSearchModalOpen}
            setIsOpen={setIsDocumentSearchModalOpen}
            documentId={transferAcceptanceDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <DocumentUpload
            isOpen={isFileUploadModalOpen}
            setIsOpen={setIsFileUploadModalOpen}
            documentId={transferAcceptanceDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <MarkScan
            isOpen={isMarkScanModal}
            setIsOpen={setIsMarkScanModal}
            documentId={transferAcceptanceDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <CheckResult
            isOpen={isCheckOpen}
            setIsOpen={setIsCheckOpen}
            problemMarks={isCheckOpen}
            docId={transferAcceptanceDocumentId}
          />
          <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
        </div>
      </div>
    </div>
  );
}

export default DocumentTransferAcceptanceOrders;


